import React from 'react'

const bespoke = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 143 81"
    >
        <g
            id="Grupo_2558"
            data-name="Grupo 2558"
            transform="translate(3603 -1323)"
        >
            <g
                id="Grupo_2557"
                data-name="Grupo 2557"
                transform="translate(-4701 -3081.897)"
            >
                <path
                    id="Caminho_3552"
                    data-name="Caminho 3552"
                    d="M0,0H40.634V40.634H0Z"
                    transform="translate(1102 4439.297)"
                    fill="#00b01e"
                />
                <path
                    id="Caminho_3553"
                    data-name="Caminho 3553"
                    d="M0,0H40.634V40.634H0Z"
                    transform="translate(1109.878 4432.649)"
                    fill="#151515"
                />
                <path
                    id="Caminho_3554"
                    data-name="Caminho 3554"
                    d="M0,0H40.634V40.634H0Z"
                    transform="translate(1119.415 4426)"
                    fill="#00b01e"
                />
            </g>
            <rect
                id="Retângulo_356"
                data-name="Retângulo 356"
                width="143"
                height="81"
                transform="translate(-3603 1323)"
                fill="none"
            />
        </g>
    </svg>
)

export default bespoke
