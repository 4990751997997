import React, { useRef, useState, useEffect } from 'react';

import Slider from 'react-slick';

import Aspas from './svg/aspas';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { injectIntl } from 'gatsby-plugin-intl';

const says = [
    {
        person: {
            name: 'Marcelo Sussa',
            occupation: 'Diretor de Tecnologia',
            company: 'WMcCANN',
        },
        message:
            'A minha parceria com a Code and Soul já transcende a 3 agências diferentes em que trabalhei nos ultimos 7 anos, nossa relação profissional tem sido o exemplo perfeito de ganha-ganha, aonde a transparência e parceria são mutuas. São uma equipe extremamente capaz e estão sempre disponíveis, o trato do dia-a-dia é algo muito fácil e bem conduzido, o que facilita muito o trabalho.',
    },
];

const sayItem = (item, id) => {
    const { brief, author, ocupation, company, remoteImage } = item.node;

    return (
        <div key={id}>
            <p
                className="clientsSayMessage"
                dangerouslySetInnerHTML={{ __html: brief }}
            ></p>
            <div className="clientsSayPerson">
                <div className="wrap-image">
                    <Img
                        fluid={remoteImage.childImageSharp.fluid}
                    />
                </div>
                <p>
                    <span>{author}</span>, {ocupation}, {company}
                </p>
            </div>
        </div>
    );
};

const ClientsSay = ({ intl, ...props }) => {
    const data = useStaticQuery(graphql`
        query TestimonyQuery {
            allCodeandsoulTestimony(sort: { fields: [created], order: DESC }) {
                edges {
                    node {
                        id
                        author
                        brief
                        company
                        photo
                        ocupation
                        created
                        remoteImage {
                            childImageSharp {
                                fluid(maxWidth: 200, maxHeight: 200) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const slide = useRef();
    const [slidePos, setSlidePos] = useState(0);

    useEffect(() => {
        if (slidePos >= says.length) {
            setSlidePos(0);
        }
        if (slidePos < 0) {
            setSlidePos(says.length - 1);
        }
    }, [slidePos]);

    const gotoNext = async () => {
        await setSlidePos(slidePos + 1);
        slide.current.slickNext();
    };

    const gotoPrev = async () => {
        await setSlidePos(slidePos - 1);
        slide.current.slickPrev();
    };

    const gotoNumber = async (id) => {
        await setSlidePos(id);
        slide.current.slickGoTo(id);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        afterChange: (id) => {
            setSlidePos(id);
        },
    };

    return (
        <section className="clientsSay" id="clientsSaySection" datanav="white">
            <div className="container">
                <div className="giantQuote">
                    <Aspas />
                </div>
                <div className="clientsSayBody">
                    <div className="clientsSayContent">
                        <h2 className="clientsSayTitle">
                            {intl.formatMessage({
                                id: 'clientsSay.title',
                            })}
                        </h2>
                        <div className="clientsSaySlide">
                            <div className="slideBody">
                                <Slider {...settings} ref={slide}>
                                    {data.allCodeandsoulTestimony.edges.map(
                                        sayItem
                                    )}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="clientsSayFooter">
                        <div className="threeDots">
                            {data.allCodeandsoulTestimony.edges.map(
                                (data, id) => {
                                    return (
                                        <span
                                            className={`dot ${
                                                slidePos === id ? 'active' : ''
                                            }`}
                                            key={id}
                                            onClick={() => gotoNumber(id)}
                                        ></span>
                                    );
                                }
                            )}
                        </div>
                        <div className="arrows">
                            <span
                                className="arrow left"
                                onClick={gotoPrev}
                            ></span>
                            <span
                                className="arrow right"
                                onClick={gotoNext}
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default injectIntl(ClientsSay);
