import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'

const HelloBanner = ({ intl }) => {
    return (
        <section className="hello-banner" datanav="black">
            <div className="container">
                <h3 className="title-hello">
                    {intl.formatMessage({
                        id: 'hello.title',
                    })}
                </h3>
                <p
                    className="hello-description"
                    dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: 'hello.description' }),
                    }}
                />
            </div>
        </section>
    )
}

export default injectIntl(HelloBanner)
