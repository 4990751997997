import React from 'react'

const Rossi = ({ bgColor = '#1f1e1d', darkMode = false }) => {
    if (darkMode) {
        bgColor = '#fff'
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="54"
            viewBox="0 0 101 54"
        >
            <g
                id="Grupo_2527"
                data-name="Grupo 2527"
                transform="translate(2391 -2855)"
            >
                <rect
                    id="Retângulo_346"
                    data-name="Retângulo 346"
                    width="101"
                    height="54"
                    transform="translate(-2391 2855)"
                    fill="#fff"
                    opacity="0"
                />
                <path
                    id="Caminho_771"
                    data-name="Caminho 771"
                    d="M895.89,538.7l2.849.012.019-9.4,4.582.02c1.206.037,3.137-.212,3.586.955.606,1.577-.823,2.2-2.13,2.19h-4.616v2.208l3.813.011,2.9,4.183,3.352,0-3-4.389c.68-.4,2.509-.682,2.6-3.313.156-4.745-5.206-4.109-7.783-4.131l-6.178,0,.015,11.65Zm22.121-11.51c4.3,0,7.775,2.643,7.775,5.9s-3.479,5.9-7.775,5.9-7.775-2.643-7.775-5.9,3.479-5.9,7.775-5.9Zm0,2.265c2.647,0,4.792,1.629,4.792,3.638s-2.144,3.638-4.792,3.638-4.792-1.629-4.792-3.638,2.144-3.638,4.792-3.638Zm8.537,6.02-.02,2.442c1.954,1.421,11.975,2.713,12.747-1.435.952-5.116-7.055-3.807-9.5-4.5-.885-.249-1.234-.9-.53-1.56,1.27-1.185,5.472-.844,7.289-.168l2.008.791v-2.382c-1.882-1.178-7.3-1.5-9.627-.625-1.224.462-2.578,1.407-2.743,2.836-.2,1.724,1.221,2.618,2.492,3.109,2.152.832,6.234.389,7.478.984.876.419.427,1.277-.277,1.611-3.52,1.665-9.162-1.128-9.318-1.1Zm13.746,0-.02,2.442c1.954,1.421,11.976,2.713,12.747-1.435.952-5.116-7.055-3.807-9.5-4.5-.885-.249-1.234-.9-.53-1.56,1.27-1.185,5.472-.844,7.289-.168l2.008.791v-2.382c-1.883-1.178-7.3-1.5-9.626-.625-1.224.462-2.578,1.407-2.743,2.836-.2,1.724,1.221,2.618,2.492,3.109,2.152.832,6.234.389,7.478.984.876.419.427,1.277-.278,1.611-3.52,1.665-9.161-1.128-9.318-1.1Zm13.661-7.794h2.626v11.648h-2.626V527.683Z"
                    transform="translate(-3266.875 2348.969)"
                    fill={bgColor}
                    fillRule="evenodd"
                />
            </g>
        </svg>
    )
}
export default Rossi
