import React from 'react'

const Elo = ({
    letterColor = '#f2f2f2',
    bgColor = '#1f1e1d',
    darkMode = false,
}) => {
    if (darkMode) {
        letterColor = '#fff'
        bgColor = '#151515'
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="54"
            viewBox="0 0 101 54"
        >
            <g
                id="Grupo_2526"
                data-name="Grupo 2526"
                transform="translate(2594 -2801)"
            >
                <rect
                    id="Retângulo_341"
                    data-name="Retângulo 341"
                    width="101"
                    height="54"
                    transform="translate(-2594 2801)"
                    fill="#fff"
                    opacity="0"
                />
                <g
                    id="Grupo_1458"
                    data-name="Grupo 1458"
                    transform="translate(-2562 2809)"
                >
                    <g
                        id="Grupo_1456"
                        data-name="Grupo 1456"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Caminho_806"
                            data-name="Caminho 806"
                            d="M149.831,222.283a18.547,18.547,0,1,1-18.547,18.547,18.547,18.547,0,0,1,18.547-18.547Z"
                            transform="translate(-131.284 -222.283)"
                            fill={bgColor}
                            fillRule="evenodd"
                        />
                        <path
                            id="Caminho_807"
                            data-name="Caminho 807"
                            d="M182.979,349.913a5.837,5.837,0,0,1,5.673,4.464l-2.352,1.028v0l-2.406,1.057-5.772,2.524a5.836,5.836,0,0,1,4.858-9.069Zm2.5,3.628-5.829,2.512c-.009-.1-.014-.2-.014-.3a3.338,3.338,0,0,1,5.843-2.207Z"
                            transform="translate(-172.15 -336.438)"
                            fill="#f2f2f2"
                            fillRule="evenodd"
                        />
                        <path
                            id="Caminho_808"
                            data-name="Caminho 808"
                            d="M208.583,427.211a5.786,5.786,0,0,1-7.282.7l1.372-2.086a3.409,3.409,0,0,0,4.165-.4l1.745,1.786Z"
                            transform="translate(-193.895 -403.868)"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <path
                            id="Caminho_809"
                            data-name="Caminho 809"
                            d="M289.864,338.269l-.015-11.035h2.091v10.737a.22.22,0,0,0,.148.246l1.819.707-.819,2.131-2.13-.9c-.808-.342-1.091-.837-1.093-1.884Z"
                            transform="translate(-273.03 -316.222)"
                            fill="#f2f2f2"
                            fillRule="evenodd"
                        />
                    </g>
                    <g
                        id="Grupo_1457"
                        data-name="Grupo 1457"
                        transform="translate(20.443 13.314)"
                    >
                        <path
                            id="Caminho_810"
                            data-name="Caminho 810"
                            d="M330.933,367.674a3.327,3.327,0,0,1-.25-5.044l-1.35-2.188a5.869,5.869,0,0,0,.325,9.447Z"
                            transform="translate(-327.104 -359.173)"
                            fill="#f2f2f2"
                            fillRule="evenodd"
                        />
                        <path
                            id="Caminho_811"
                            data-name="Caminho 811"
                            d="M361.833,351.534a3.332,3.332,0,0,1,4.243,1.96l2.587-.216a5.871,5.871,0,0,0-7.911-4.051Z"
                            transform="translate(-357.089 -348.797)"
                            fill="#f2f2f2"
                            fillRule="evenodd"
                        />
                        <path
                            id="Caminho_812"
                            data-name="Caminho 812"
                            d="M369.4,404.191a3.331,3.331,0,0,1-4.137,3.069l-1.015,2.35a5.871,5.871,0,0,0,7.691-5.339Z"
                            transform="translate(-360.206 -398.159)"
                            fill="#f2f2f2"
                            fillRule="evenodd"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
export default Elo
