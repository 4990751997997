import React from 'react'

const aspas = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91 70.2">
            <g id="Camada_2" data-name="Camada 2">
                <g id="Camada_1-2" data-name="Camada 1">
                    <path
                        d="M33.4,0H4.8A4.87,4.87,0,0,0,0,4.8V39.2A4.87,4.87,0,0,0,4.8,44H22.4V62.2a7.9,7.9,0,1,0,15.8,0V4.8A4.87,4.87,0,0,0,33.4,0Z"
                        fill="#fff"
                    />
                    <path
                        d="M86.2,0H57.6a4.87,4.87,0,0,0-4.8,4.8V39.2A4.87,4.87,0,0,0,57.6,44H75.2V62.2a7.9,7.9,0,1,0,15.8,0V4.8A4.87,4.87,0,0,0,86.2,0Z"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    )
}

export default aspas
