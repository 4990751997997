import React from 'react'

const BtgPactual = ({ bgColor = '#151515', darkMode = false }) => {
    if (darkMode) {
        bgColor = '#fff'
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="54"
            viewBox="0 0 101 54"
        >
            <g
                id="Grupo_2533"
                data-name="Grupo 2533"
                transform="translate(2594 -2855)"
            >
                <rect
                    id="Retângulo_344"
                    data-name="Retângulo 344"
                    width="101"
                    height="54"
                    transform="translate(-2594 2855)"
                    fill="#fff"
                    opacity="0"
                />
                <g
                    id="Grupo_2536"
                    data-name="Grupo 2536"
                    transform="translate(-2766.082 2481.417)"
                >
                    <path
                        id="Caminho_4021"
                        data-name="Caminho 4021"
                        d="M208.584,404.327a10.877,10.877,0,0,1-1.819,4.021,13.832,13.832,0,0,1-7.505,5.565A13.981,13.981,0,0,1,185.481,390.4a13.8,13.8,0,0,1,8.767-3.786,12.155,12.155,0,0,1,4.172.421l-.01.1c-.317-.039-.634-.079-.951-.116a12.419,12.419,0,0,0-8.131,1.854,13.226,13.226,0,0,0-6.235,8.756,12.467,12.467,0,0,0,5.87,13.494,13.173,13.173,0,0,0,13.446.432,11.831,11.831,0,0,0,5.891-6.784c.046-.13.1-.258.151-.387a.586.586,0,0,1,.049-.081Z"
                    />
                    <path
                        id="Caminho_4022"
                        data-name="Caminho 4022"
                        d="M327.692,455.8a8.7,8.7,0,0,0,.609-3.753c.014-1.545,0-3.091,0-4.636a3.316,3.316,0,0,0-.387-1.809c.113-.015.193-.033.274-.036,1.427-.054,2.853-.123,4.28-.15a4.623,4.623,0,0,1,1.388.156,1.952,1.952,0,0,1,.748,3.335,11.189,11.189,0,0,1-1.356.992,2.309,2.309,0,0,1,.279.1,13.061,13.061,0,0,1,1.421.7,1.763,1.763,0,0,1,.76,1.251,2.968,2.968,0,0,1-2.44,3.652,10.9,10.9,0,0,1-2.028.192C330.082,455.818,328.924,455.8,327.692,455.8Zm2.257-5.309a1.044,1.044,0,0,0-.035.178c0,.905,0,1.811.016,2.716a4.478,4.478,0,0,0,.094.855.822.822,0,0,0,.887.754,6.359,6.359,0,0,0,1.363-.105A1.846,1.846,0,0,0,333.9,453.1a1.99,1.99,0,0,0-1.176-2.34A7.759,7.759,0,0,0,329.949,450.488Zm-.037-2.5h0c0,.5-.007,1,.007,1.5,0,.086.1.242.153.244a4.741,4.741,0,0,0,2.55-.437,1.748,1.748,0,0,0,.834-1.917c-.124-.664-.511-1.022-1.365-1.115-.643-.07-1.3-.032-1.946-.043-.181,0-.236.093-.233.262C329.916,446.987,329.912,447.488,329.912,447.989Z"
                        transform="translate(-127.748 -51.261)"
                    />
                    <path
                        id="Caminho_4023"
                        data-name="Caminho 4023"
                        d="M448.826,446.71c-.381-.258-.7-.514-1.05-.7a3.618,3.618,0,0,0-5.465,2.328,6,6,0,0,0,.1,3.5,3.607,3.607,0,0,0,3.579,2.618c.36.011.723-.037,1.084-.049a.27.27,0,0,0,.3-.3c0-1.137.013-2.274-.013-3.411a2.914,2.914,0,0,0-.234-.749h2.122a13.479,13.479,0,0,0-.245,4.6c0,.157.022.314.037.52-.606.063-1.185.141-1.767.18a11.021,11.021,0,0,1-3.434-.16,4.241,4.241,0,0,1-3.376-3.926,6.632,6.632,0,0,1,.478-3.533,4.959,4.959,0,0,1,3.56-2.752,10.338,10.338,0,0,1,3.687-.1c.641.075.64.082.64.74Z"
                        transform="translate(-225.966 -50.605)"
                    />
                    <path
                        id="Caminho_4024"
                        data-name="Caminho 4024"
                        d="M523.664,445.579c1.276-.06,2.488-.131,3.7-.167a6.584,6.584,0,0,1,1.43.088,2.461,2.461,0,0,1,2.163,2.715,2.989,2.989,0,0,1-2.225,3,4.029,4.029,0,0,1-1.961.008,4.371,4.371,0,0,1-.665-.275l.011-.081c.136-.025.272-.052.408-.074.179-.029.359-.051.537-.082a2.255,2.255,0,0,0,2.037-2.675,1.711,1.711,0,0,0-1.518-1.8,3.214,3.214,0,0,0-.509-.033c-.33,0-.661,0-.991,0-.408,0-.409,0-.41.423q0,3.469,0,6.938a3.807,3.807,0,0,0,.468,2.154H523.73a6.929,6.929,0,0,0,.271-.981,10.739,10.739,0,0,0,.068-1.371q.01-2.878,0-5.755A3.672,3.672,0,0,0,523.664,445.579Z"
                        transform="translate(-298.507 -51.256)"
                    />
                    <path
                        id="Caminho_4025"
                        data-name="Caminho 4025"
                        d="M712.963,468.254l1.762-.281c0,.159,0,.273,0,.386,0,1.917,0,3.835,0,5.752a1.5,1.5,0,0,0,.441,1.265l-1.766.107-.05-.747-.305.251a2.673,2.673,0,0,1-1.982.648,1.989,1.989,0,0,1-1.867-2.213c-.009-1.406-.005-2.812,0-4.218a.969.969,0,0,0-.474-1c.492-.077.906-.153,1.322-.2s.516.047.517.468c0,1.278-.005,2.556,0,3.835a8.232,8.232,0,0,0,.075,1.24,1.169,1.169,0,0,0,1.207,1.093,1.2,1.2,0,0,0,1.328-.954,7.171,7.171,0,0,0,.176-1.482c.026-.969.008-1.939.007-2.908C713.362,468.906,713.382,468.51,712.963,468.254Z"
                        transform="translate(-459.76 -70.919)"
                    />
                    <path
                        id="Caminho_4026"
                        data-name="Caminho 4026"
                        d="M390.554,447.652c-.962.111-1.912-.207-2.783.4l-.477-1.293h8.886a.845.845,0,0,1-.6.669,7.117,7.117,0,0,1-1.374.236c-.551.039-1.108.008-1.662.01-.114,0-.227.012-.37.02v.377q0,3.517,0,7.035a3.116,3.116,0,0,0,.4,1.782h-2.412a4.484,4.484,0,0,0,.388-2.488c.007-2.1,0-4.2,0-6.3Z"
                        transform="translate(-179.682 -52.432)"
                    />
                    <path
                        id="Caminho_4027"
                        data-name="Caminho 4027"
                        d="M768.653,475.354l-1.657.1-.2-.94c-.112.129-.2.223-.278.323a2.389,2.389,0,0,1-3.169.334,1.657,1.657,0,0,1-.085-2.549,9.97,9.97,0,0,1,1.688-1.01c.4-.217.83-.386,1.25-.567a.89.89,0,0,0,.574-.876c0-.128.008-.256,0-.384a1.145,1.145,0,0,0-1.531-1.169.927.927,0,0,0-.659.508,6.426,6.426,0,0,1-.333.653.784.784,0,0,1-.97.239.659.659,0,0,1-.282-.856,1.294,1.294,0,0,1,.757-.781,5.138,5.138,0,0,1,3.436-.257,1.165,1.165,0,0,1,.945,1.334c.007,1.332,0,2.664,0,4A2.952,2.952,0,0,0,768.653,475.354Zm-1.843-3.812-.106-.061c-.555.283-1.114.559-1.662.853a1.944,1.944,0,0,0-.481.362,1.155,1.155,0,0,0-.241,1.271.97.97,0,0,0,1,.6,1.247,1.247,0,0,0,1.381-1.1A16.587,16.587,0,0,0,766.811,471.542Z"
                        transform="translate(-506.795 -70.89)"
                    />
                    <path
                        id="Caminho_4028"
                        data-name="Caminho 4028"
                        d="M587.038,475.406c-.49.03-.946.052-1.4.087a.229.229,0,0,1-.282-.219c-.038-.218-.086-.435-.144-.719-.151.177-.273.3-.373.439a2.388,2.388,0,0,1-3.232.089,1.7,1.7,0,0,1,0-2.365,6.641,6.641,0,0,1,2.173-1.247c.32-.145.639-.291.953-.447a.736.736,0,0,0,.431-.628,4.384,4.384,0,0,0,.029-.573,1.131,1.131,0,0,0-1.6-1.121,1.567,1.567,0,0,0-.591.485,2.216,2.216,0,0,0-.237.484.79.79,0,0,1-1.1.373.722.722,0,0,1-.15-1.045,1.821,1.821,0,0,1,1.14-.765,5.715,5.715,0,0,1,2.655-.144,1.271,1.271,0,0,1,1.235,1.483q0,1.9,0,3.8A3.438,3.438,0,0,0,587.038,475.406Zm-1.888-3.928c-.721.408-1.4.789-2.075,1.177a.642.642,0,0,0-.174.184,1.154,1.154,0,0,0-.157,1.2.983.983,0,0,0,.952.575,1.284,1.284,0,0,0,1.439-1.184A16.065,16.065,0,0,0,585.149,471.478Z"
                        transform="translate(-348.583 -70.941)"
                    />
                    <path
                        id="Caminho_4029"
                        data-name="Caminho 4029"
                        d="M635.582,473.987a1.206,1.206,0,0,1-.687,1.322,3.373,3.373,0,0,1-5-2.415,4.259,4.259,0,0,1,.839-3.464,4,4,0,0,1,3.807-1.33c.836.095.834.113.838.957,0,.212,0,.424,0,.739-.141-.106-.252-.148-.29-.225a1.772,1.772,0,0,0-2.922-.079,3.486,3.486,0,0,0-.594,1.355,6.873,6.873,0,0,0-.013,2.028,2.134,2.134,0,0,0,2.948,1.673A7.917,7.917,0,0,0,635.582,473.987Z"
                        transform="translate(-391.018 -70.991)"
                    />
                    <path
                        id="Caminho_4030"
                        data-name="Caminho 4030"
                        d="M257.092,411.906l-.982-1.324a12.953,12.953,0,0,1,7.373-3.677,10.4,10.4,0,0,1,6.456,1.021c-.532-.126-1.06-.276-1.6-.373a10.013,10.013,0,0,0-4.484.327,15.115,15.115,0,0,0-6.523,3.8C257.267,411.745,257.2,411.809,257.092,411.906Z"
                        transform="translate(-65.376 -17.6)"
                    />
                    <path
                        id="Caminho_4031"
                        data-name="Caminho 4031"
                        d="M249.289,534.7l.961,1.3c-3.86,4.033-10.179,4.782-13.8,2.671.438.116.863.258,1.3.34a10.484,10.484,0,0,0,4.8-.293A15.529,15.529,0,0,0,249.289,534.7Z"
                        transform="translate(-48.248 -129.063)"
                    />
                    <path
                        id="Caminho_4032"
                        data-name="Caminho 4032"
                        d="M685.068,460.68c-.014.207-.019.438-.05.666a.376.376,0,0,1-.129.243,2.813,2.813,0,0,1-2,.594A1.245,1.245,0,0,1,681.743,461c-.057-.805-.048-1.616-.045-2.424.007-1.735.04-3.471.033-5.206,0-.357.067-.55.447-.6a7.558,7.558,0,0,0,.867-.214c0,.665.013,1.281-.006,1.9-.009.276.065.388.353.365a5.619,5.619,0,0,1,.83,0c.1.007.272.094.268.134-.009.113-.065.283-.149.316a3.491,3.491,0,0,1-.849.235c-.444.055-.447.034-.447.468q0,2.109,0,4.217c0,.021,0,.043,0,.064.006.811.305,1.04,1.085.8C684.45,460.957,684.754,460.807,685.068,460.68Z"
                        transform="translate(-436.208 -57.489)"
                    />
                    <path
                        id="Caminho_4033"
                        data-name="Caminho 4033"
                        d="M813.6,446.99l1.881-.29c0,.158,0,.282,0,.406.009,2.782.009,5.563.037,8.345a10.278,10.278,0,0,0,.2,1.383H813.83a5.474,5.474,0,0,0,.3-2.741c.018-1.929,0-3.858-.007-5.788A1.445,1.445,0,0,0,813.6,446.99Z"
                        transform="translate(-551.142 -52.383)"
                    />
                    <path
                        id="Caminho_4034"
                        data-name="Caminho 4034"
                        d="M281.126,423.287l-.963-1.264c.343-.286.665-.572,1.006-.836a13.813,13.813,0,0,1,6.168-2.847,8.614,8.614,0,0,1,3.94.174,11.011,11.011,0,0,0-5.624,1.5,19.55,19.55,0,0,0-4.231,3.005C281.337,423.1,281.25,423.176,281.126,423.287Z"
                        transform="translate(-86.334 -27.543)"
                    />
                    <path
                        id="Caminho_4035"
                        data-name="Caminho 4035"
                        d="M232.7,528.411a9.063,9.063,0,0,0,4.137-.748,18.831,18.831,0,0,0,5.89-3.813c.047-.043.1-.084.147-.123a.411.411,0,0,1,.087-.032l.911,1.2C240.94,527.79,236.261,529.518,232.7,528.411Z"
                        transform="translate(-44.979 -119.472)"
                    />
                    <path
                        id="Caminho_4036"
                        data-name="Caminho 4036"
                        d="M248.44,564.149a12.9,12.9,0,0,0,10.2-.661c.547-.256,1.051-.6,1.584-.893a.309.309,0,0,1,.287.015c.3.358.575.734.877,1.128a12.52,12.52,0,0,1-7.032,1.944A12.871,12.871,0,0,1,248.44,564.149Z"
                        transform="translate(-58.692 -153.344)"
                    />
                    <path
                        id="Caminho_4037"
                        data-name="Caminho 4037"
                        d="M250.929,396.45a12.6,12.6,0,0,1,7.1-1.929,12.746,12.746,0,0,1,5.819,1.529c-.636-.177-1.263-.4-1.909-.522a13.024,13.024,0,0,0-7.825.973,20.8,20.8,0,0,0-1.985,1.087c-.166.1-.266.129-.391-.048C251.489,397.183,251.221,396.84,250.929,396.45Z"
                        transform="translate(-60.861 -6.914)"
                    />
                    <path
                        id="Caminho_4038"
                        data-name="Caminho 4038"
                        d="M345,484.824a7.758,7.758,0,0,1,2.78.27,1.99,1.99,0,0,1,1.176,2.34,1.845,1.845,0,0,1-1.631,1.788,6.357,6.357,0,0,1-1.363.105.822.822,0,0,1-.887-.754,4.479,4.479,0,0,1-.094-.855c-.015-.905-.013-1.811-.016-2.716A1.045,1.045,0,0,1,345,484.824Z"
                        transform="translate(-142.798 -85.597)"
                        fill="#fff"
                    />
                    <path
                        id="Caminho_4039"
                        data-name="Caminho 4039"
                        d="M344.92,453.481c0-.5,0-1,0-1.5,0-.17.052-.265.234-.262.649.011,1.3-.026,1.945.043.854.092,1.241.451,1.365,1.115a1.748,1.748,0,0,1-.834,1.917,4.741,4.741,0,0,1-2.55.437c-.056,0-.15-.157-.153-.244-.015-.5-.007-1-.007-1.5Z"
                        transform="translate(-142.756 -56.753)"
                        fill="#fff"
                    />
                    <path
                        id="Caminho_4040"
                        data-name="Caminho 4040"
                        d="M777.132,495.527a16.574,16.574,0,0,1-.113,1.928,1.247,1.247,0,0,1-1.381,1.1.97.97,0,0,1-1-.6,1.155,1.155,0,0,1,.241-1.271,1.943,1.943,0,0,1,.481-.362c.549-.294,1.107-.57,1.662-.853Z"
                        transform="translate(-517.116 -94.875)"
                        fill="#fff"
                    />
                    <path
                        id="Caminho_4041"
                        data-name="Caminho 4041"
                        d="M595.357,495.048a16.065,16.065,0,0,1-.015,1.955,1.284,1.284,0,0,1-1.439,1.184.983.983,0,0,1-.952-.575,1.155,1.155,0,0,1,.157-1.2.643.643,0,0,1,.174-.184C593.957,495.837,594.636,495.456,595.357,495.048Z"
                        transform="translate(-358.79 -94.511)"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    )
}
export default BtgPactual
