import React from 'react'

const Nivea = ({
    letterColor = '#f2f2f2',
    bgColor = '#1f1e1d',
    darkMode = false,
}) => {
    if (darkMode) {
        letterColor = '#151515'
        bgColor = '#fff'
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="54"
            viewBox="0 0 101 54"
        >
            <g
                id="Grupo_2531"
                data-name="Grupo 2531"
                transform="translate(2594 -2963)"
            >
                <rect
                    id="Retângulo_350"
                    data-name="Retângulo 350"
                    width="101"
                    height="54"
                    transform="translate(-2594 2963)"
                    fill="#fff"
                    opacity="0"
                />
                <g
                    id="Grupo_1452"
                    data-name="Grupo 1452"
                    transform="translate(-2562 2971)"
                >
                    <path
                        id="Caminho_773"
                        data-name="Caminho 773"
                        d="M76.089,73.007A18.978,18.978,0,1,1,57.111,54.03,18.978,18.978,0,0,1,76.089,73.007"
                        transform="translate(-38.133 -54.03)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_774"
                        data-name="Caminho 774"
                        d="M510.119,394.794h-1.946v.869l1.946,0v1.174h-1.946v.964h1.946v1.617H506.15v-6.194h3.969Z"
                        transform="translate(-484.206 -377.318)"
                        fill="#f2f2f2"
                    />
                    <path
                        id="Caminho_775"
                        data-name="Caminho 775"
                        d="M604.4,387.355s.775-1.588.775-1.588.76,1.593.76,1.588Zm.775-5.006c-1.255,2.215-3.726,6.7-3.726,6.7h2.117l.343-.674h2.528l.333.674H608.9Z"
                        transform="translate(-575.035 -366.955)"
                        fill="#f2f2f2"
                    />
                    <path
                        id="Caminho_776"
                        data-name="Caminho 776"
                        d="M351.4,396l1.389-2.776h2.158c.008,0-3.548,6.655-3.548,6.655l-3.543-6.655h2.16S351.294,395.8,351.4,396"
                        transform="translate(-333.339 -377.318)"
                        fill="#f2f2f2"
                    />
                    <path
                        id="Caminho_777"
                        data-name="Caminho 777"
                        d="M299.635,399.41H297.61v-6.194s2.025.008,2.025,0Z"
                        transform="translate(-285.444 -377.312)"
                        fill="#f2f2f2"
                    />
                    <path
                        id="Caminho_778"
                        data-name="Caminho 778"
                        d="M148.527,386.085v-2.4h2.025v6.655l-3.288-2.86v2.4h-2.027v-6.663Z"
                        transform="translate(-140.215 -367.784)"
                        fill="#f2f2f2"
                    />
                </g>
            </g>
        </svg>
    )
}
export default Nivea
