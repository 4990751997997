import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { injectIntl } from 'gatsby-plugin-intl'

import { TimelineLite, Power4 } from 'gsap'

// eslint-disable-next-line no-unused-vars
import io from 'intersection-observer'

const apps = [
    {
        name: 'Firstoeat',
        type: 'App Mobile',
        image: 'firstoeat',
        link: '#firstoeat',
        bgColor: '#e02924',
    },
    {
        name: 'BETV',
        type: 'TV',
        image: 'betv',
        link: '#betv',
        bgColor: '#d3dbe3',
    },
    {
        name: 'Hofbauer',
        type: 'Plataforma',
        image: 'hofbauer',
        link: '#hofbauer',
        bgColor: '#4d536a',
    },
    {
        name: 'Pet in Time',
        type: 'App Mobile',
        image: 'petintime',
        link: '#petintime',
        bgColor: '#064E5B',
    },
    {
        name: 'Whoslate',
        type: 'Plataforma',
        image: 'whoslate',
        link: '#whoslate',
        bgColor: '#2d1668',
    },
    {
        name: 'Biolab',
        type: 'Plataforma',
        image: 'biolab',
        link: '#biolab',
        bgColor: '#f1f9fa',
    },
]
const cases = (app, allCases) => {
    const image = allCases.allFile.nodes.find(el => el.name === app.image)

    return (
        <div key={app.name} className="app">
            <div className="wrap-box-transition">
                <span
                    className="box-transition"
                    style={{ backgroundColor: app.bgColor }}
                />
                <span className="box-transition-wrap" />
            </div>

            <div className="wrap-image-work">
                <Img
                    fluid={image.childImageSharp.fluid}
                    className="image-work"
                />
            </div>

            <span className="appname" href={app.link}>
                {app.name} / {app.type}
            </span>
        </div>
    )
}

const Work = ({ intl, ...props }) => {
    let container
    const timeLineAnimation = []
    const { isMobile } = props

    const playAnimation = (entries, startFunction) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                startFunction.play()
            }
        })
    }

    useEffect(() => {
        const appsContainer = container.querySelectorAll(' .app ')

        for (const key in appsContainer) {
            if (appsContainer.hasOwnProperty(key)) {
                const appContainer = appsContainer[key]
                const boxTransitionWrap = appContainer.getElementsByClassName(
                    'wrap-box-transition'
                )

                timeLineAnimation[key] = new TimelineLite({
                    paused: true,
                    delay: 0.4,
                })

                if (key % 2 == 0) {
                    timeLineAnimation[key].to(
                        boxTransitionWrap,
                        0.4,
                        {
                            right: '100%',
                            ease: Power4.inOut,
                        },
                        0.4 * key
                    )
                } else {
                    timeLineAnimation[key].to(
                        boxTransitionWrap,
                        0.4,
                        {
                            left: '100%',
                            ease: Power4.inOut,
                        },
                        0.4 * key
                    )
                }

                // eslint-disable-next-line no-undef
                const intersectionObserver = new IntersectionObserver(
                    (entries, observer) =>
                        playAnimation(entries, timeLineAnimation[key])
                )
                intersectionObserver.observe(appContainer)
            }
        }
    }, [])

    const allImageCases = useStaticQuery(graphql`
        query {
            allFile(filter: { relativeDirectory: { eq: "apps" } }) {
                nodes {
                    name
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `)

    return (
        <section
            className="work"
            id="workSection"
            ref={el => (container = el)}
            datanav="black"
        >
           <div className="container">
                <div className="wrap-work">
                    <div className="wrap-title">
                        <h2 className="title">
                            {intl.formatMessage({
                                id: 'work.title',
                            })}
                        </h2>
                        <h3 className="subtitle">
                            {intl.formatMessage({
                                id: 'work.subtitle',
                            })}
                        </h3>
                    </div>
                    {apps.map(app => cases(app, allImageCases))}
                </div>
           </div>
        </section>
    )
}

export default injectIntl(Work)
