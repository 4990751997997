import React from 'react'

const bancoDoBrasil = ({ bgColor = '#1f1e1d', darkMode = false }) => {
    if (darkMode) {
        bgColor = '#fff'
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="54"
            viewBox="0 0 101 54"
        >
            <g
                id="Grupo_2529"
                data-name="Grupo 2529"
                transform="translate(2391 -2963)"
            >
                <rect
                    id="Retângulo_352"
                    data-name="Retângulo 352"
                    width="101"
                    height="54"
                    transform="translate(-2391 2963)"
                    fill="#fff"
                    opacity="0"
                />
                <g
                    id="Grupo_1450"
                    data-name="Grupo 1450"
                    transform="translate(-2358 2973)"
                >
                    <path
                        id="Caminho_743"
                        data-name="Caminho 743"
                        d="M437.389,420.925l-16.843,11.29,7.01,4.552,8.376-5.645-3.322-2.182,4.551-3.049,9.875,6.553h0l-16.2,10.877,3.5,2.321,20.16-13.425-.045-.045-17.065-11.246Z"
                        transform="translate(-420.271 -420.925)"
                        fill={bgColor}
                        fillRule="evenodd"
                    />
                    <path
                        id="Caminho_744"
                        data-name="Caminho 744"
                        d="M552.226,420.947l-7.008,4.642,3.5,2.366,3.5-2.366v-4.642Z"
                        transform="translate(-518.312 -420.942)"
                        fill={bgColor}
                        fillRule="evenodd"
                    />
                    <path
                        id="Caminho_745"
                        data-name="Caminho 745"
                        d="M420.005,554.032l7.008-4.642-3.5-2.367-3.5,2.367v4.642Z"
                        transform="translate(-419.846 -520.086)"
                        fill={bgColor}
                        fillRule="evenodd"
                    />
                    <path
                        id="Caminho_746"
                        data-name="Caminho 746"
                        d="M436.368,488.8l16.843-11.29-7.01-4.552-8.376,5.645,3.322,2.183-4.551,3.049-9.875-6.553h0l16.2-10.877-3.5-2.321-20.16,13.425.046.046L436.368,488.8Z"
                        transform="translate(-419.258 -454.861)"
                        fill={bgColor}
                        fillRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    )
}

export default bancoDoBrasil
