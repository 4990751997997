import React, { useEffect } from 'react';

import { TimelineLite, Power4 } from 'gsap';

import { injectIntl } from 'gatsby-plugin-intl';

import BancoDoBrasil from './svg/clients/bancoDoBrasil';
import BtgPactual from './svg/clients/btgPactual';
import Elo from './svg/clients/elo';
import Fca from './svg/clients/fca';
import Firstoeat from './svg/clients/firstoeat';
import Jeep from './svg/clients/jeep';
import JohnsonControls from './svg/clients/johnsonControls';
import Mube from './svg/clients/mube';
import Nivea from './svg/clients/nivea';
import Rossi from './svg/clients/rossi';
import Samsung from './svg/clients/samsung';
import Whoslate from './svg/clients/whoslate';

const list = [
    Elo,
    Mube,
    Samsung,
    BtgPactual,
    Whoslate,
    Rossi,
    JohnsonControls,
    Firstoeat,
    Fca,
    Nivea,
    Jeep,
    BancoDoBrasil,
];

const Clients = ({ intl, ...darkMode }) => {
    // eslint-disable-next-line prefer-const
    let allTransitionBox = [];

    useEffect(() => {
        allTransitionBox.map((transitionBox, key) => {
            const timeLine = new TimelineLite({ paused: true, delay: 0.4 });

            timeLine.to(
                transitionBox,
                0.6,
                {
                    // left: '100%',
                    opacity: 1,
                    ease: Power4.inOut,
                },
                0.1 * key
            );

            // eslint-disable-next-line no-undef
            const intersectionObserver = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            timeLine.play();
                        }
                    });
                }
            );

            intersectionObserver.observe(transitionBox);
        });
    }, []);

    return (
        <section className="clients" datanav="black">
            <div className="container">
                <h2 className="title">
                    {intl.formatMessage({
                        id: 'clients.title',
                    })}
                </h2>
                <span className="description">
                    {intl.formatMessage({
                        id: 'clients.description',
                    })}
                </span>

                <div className="wrap-list-clients">
                    {list.map((Client, index) => (
                        <div
                            className="clients-logo"
                            key={`clients-${index}`}
                            ref={(el) => allTransitionBox.push(el)}
                        >
                            <Client darkMode={false} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default injectIntl(Clients);
