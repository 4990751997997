import React, { useEffect, useRef, useState, useContext } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import gsap from 'gsap';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';

import useResponsiveCanvas from './canvas';
import brainShader from '../shaders/brain';

import ThemeContext from '../context/theme-context';

const HeroBanner = () => {
    const { setHeaderAnimation, isMobile } = useContext(ThemeContext);

    const intl = useIntl();

    const [init, setInit] = useState(false);
    const [animateFrame, setAnimateFrame] = useState(null);

    const { canvas, mountRef, size } = useResponsiveCanvas();
    const [width, height] = size;

    let innerWidth = 0;
    let innerHeight = 0;

    if (typeof window !== 'undefined') {
        innerWidth = window.innerWidth;
        innerHeight = window.innerHeight;
    }

    const [fov, aspect, near, far] = [70, innerWidth / innerHeight, 1, 1000];

    const titleRef = useRef();
    const titleAnimRef = useRef();

    const settings = useRef({
        progress: 0,
    });

    const rendererRef = useRef(
        typeof window !== 'undefined'
            ? new THREE.WebGLRenderer({ canvas, alpha: true, antialias: true })
            : null
    );

    const cameraRef = useRef(
        typeof window !== 'undefined'
            ? new THREE.PerspectiveCamera(fov, aspect, near, far)
            : null
    );

    const sceneRef = useRef(new THREE.Scene());

    const surfaceColor = new THREE.Color(0xeeeeee);
    const insideColor = new THREE.Color(0x252525);

    const getRandomAxis = () => {
        return new THREE.Vector3(
            Math.random() - 0.5,
            Math.random() - 0.5,
            Math.random() - 0.5
        ).normalize();
    };

    const getCentroid = (geometry) => {
        const ar = geometry.attributes.position.array;
        const len = ar.length;

        let [x, y, z] = [0, 0, 0];

        for (let i = 0; i < len; i = i + 3) {
            x += ar[i];
            y += ar[i + 1];
            z += ar[i + 2];
        }

        return { x: (3 * x) / len, y: (3 * y) / len, z: (3 * z) / len };
    };

    const processSurface = (v, j) => {
        const c = v.position;
        let vtemp, vtemp1;

        vtemp = v.children[0].geometry.clone();

        vtemp = vtemp.applyMatrix4(
            new THREE.Matrix4().makeRotationX(-Math.PI / 2)
        );

        vtemp = vtemp.applyMatrix4(
            new THREE.Matrix4().makeTranslation(c.x, c.y, c.z)
        );

        vtemp1 = v.children[1].geometry;

        vtemp1 = vtemp1.applyMatrix4(
            new THREE.Matrix4().makeRotationX(-Math.PI / 2)
        );

        vtemp1 = vtemp1
            .clone()
            .applyMatrix4(new THREE.Matrix4().makeTranslation(c.x, c.y, c.z));

        const len = v.children[0].geometry.attributes.position.array.length / 3;

        const len1 =
            v.children[1].geometry.attributes.position.array.length / 3;

        const offset = new Array(len).fill(j / 100);

        vtemp.setAttribute(
            'offset',
            new THREE.BufferAttribute(new Float32Array(offset), 1)
        );

        const offset1 = new Array(len1).fill(j / 100);

        vtemp1.setAttribute(
            'offset',
            new THREE.BufferAttribute(new Float32Array(offset1), 1)
        );

        // axis
        let axis = getRandomAxis();

        let axes = new Array(len * 3).fill(0);
        let axes1 = new Array(len1 * 3).fill(0);

        for (let i = 0; i < len * 3; i = i + 3) {
            axes[i] = axis.x;
            axes[i + 1] = axis.y;
            axes[i + 2] = axis.z;
        }

        vtemp.setAttribute(
            'axis',
            new THREE.BufferAttribute(new Float32Array(axes), 3)
        );

        for (let i = 0; i < len1 * 3; i = i + 3) {
            axes1[i] = axis.x;
            axes1[i + 1] = axis.y;
            axes1[i + 2] = axis.z;
        }

        vtemp1.setAttribute(
            'axis',
            new THREE.BufferAttribute(new Float32Array(axes1), 3)
        );

        let centroidVector = getCentroid(vtemp);

        let centroid = new Array(len * 3).fill(0);
        let centroid1 = new Array(len1 * 3).fill(0);

        for (let i = 0; i < len * 3; i = i + 3) {
            centroid[i] = centroidVector.x;
            centroid[i + 1] = centroidVector.y;
            centroid[i + 2] = centroidVector.z;
        }

        vtemp.setAttribute(
            'pos',
            new THREE.BufferAttribute(new Float32Array(centroid), 3)
        );

        for (let i = 0; i < len1 * 3; i = i + 3) {
            centroid1[i] = centroidVector.x;
            centroid1[i + 1] = centroidVector.y;
            centroid1[i + 2] = centroidVector.z;
        }

        vtemp1.setAttribute(
            'pos',
            new THREE.BufferAttribute(new Float32Array(centroid1), 3)
        );

        return { surface: vtemp, volume: vtemp1 };
    };

    const load = (scene, material, material1, inverted) => {
        const draco = new DRACOLoader();
        draco.setDecoderPath('/lib/draco/');

        const loader = new GLTFLoader().setPath('/models/');
        loader.setDRACOLoader(draco);

        inverted = inverted || false;

        let voron = [];

        loader.load(
            'br3.glb',
            (gltf) => {
                gltf.scene.traverse((child) => {
                    if (child.name === 'Voronoi_Fracture') {
                        if (child.children[0].children.length > 2) {
                            child.children.forEach((f) => {
                                f.children.forEach((m) => {
                                    voron.push(m.clone());
                                });
                            });
                        } else {
                            child.children.forEach((m) => {
                                voron.push(m.clone());
                            });
                        }
                    }
                });

                let geoms = [];
                let geoms1 = [];

                let j = 0;

                voron = voron.filter((v) => {
                    if (v.isMesh) {
                        return false;
                    } else {
                        j++;

                        let vtempo = processSurface(v, j);

                        if (inverted) {
                            geoms1.push(vtempo.surface);
                            geoms.push(vtempo.volume);
                        } else {
                            geoms.push(vtempo.surface);
                            geoms1.push(vtempo.volume);
                        }

                        return true;
                    }
                });

                const s = BufferGeometryUtils.mergeBufferGeometries(
                    geoms,
                    false
                );

                const mesh = new THREE.Mesh(s, material);
                mesh.frustumCulled = false;

                scene.add(mesh);

                const s1 = BufferGeometryUtils.mergeBufferGeometries(
                    geoms1,
                    false
                );

                const mesh1 = new THREE.Mesh(s1, material1);
                mesh1.frustumCulled = false;

                scene.add(mesh1);
            },
            (xhr) => {
                console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
                // if ( xhr.loaded === xhr.total ) {

                // }
            },
            (error) => {
                console.log(error);

                return false;
            }
        );
    };

    const addObjects = () => {
        const [path, format] = ['/newsky/', '.jpg'];

        const urls = [
            path + 'px' + format,
            path + 'nx' + format,
            path + 'py' + format,
            path + 'ny' + format,
            path + 'pz' + format,
            path + 'nz' + format,
        ];

        const textureCube = new THREE.CubeTextureLoader().load(urls);

        const material = new THREE.ShaderMaterial({
            extensions: {
                derivatives: '#extension GL_OES_standard_derivatives : enable',
            },
            side: THREE.DoubleSide,
            uniforms: {
                time: { type: 'f', value: 0 },
                progress: { type: 'f', value: 0 },
                inside: { type: 'f', value: 0 },
                surfaceColor: { type: 'v3', value: surfaceColor },
                insideColor: { type: 'v3', value: insideColor },
                tCube: { value: textureCube },
                pixels: {
                    type: 'v2',
                    value: new THREE.Vector2(
                        window.innerWidth,
                        window.innerHeight
                    ),
                },
                uvRate1: {
                    value: new THREE.Vector2(1, 1),
                },
            },
            vertexShader: brainShader.vertex(),
            fragmentShader: brainShader.fragment(),
        });

        const material1 = material.clone();
        material1.uniforms.inside.value = 1;

        return [material, material1];
    };

    useEffect(() => {
        if (!isMobile) {
            const mount = mountRef.current;
            const renderer = rendererRef.current;
            const camera = cameraRef.current;
            const scene = sceneRef.current;

            camera.position.set(0, 0, 1);
            camera.position.z = 500;

            const [material, material1] = addObjects();

            load(scene, material, material1);

            renderer.setClearColor(0x000000, 0);
            renderer.setPixelRatio(window.devicePixelRatio);

            scene.add(camera);

            mount.appendChild(renderer.domElement);

            const animate = () => {
                material.uniforms.progress.value = Math.abs(
                    settings.current.progress
                );

                material1.uniforms.progress.value = Math.abs(
                    settings.current.progress
                );

                if (scene.children[1] && !init) {
                    setInit(true);
                }

                if (window) {
                    // eslint-disable-next-line no-undef
                    setAnimateFrame(requestAnimationFrame(animate));
                }
                renderer.render(scene, camera);
            };

            animate();
        }
    }, [mountRef, settings.current]);

    useEffect(() => {
        if (init && !isMobile) {
            const scene = sceneRef.current;

            const tl = gsap.timeline({
                repeat: 2,
                onComplete: () => {
                    const tl2 = gsap.timeline();

                    tl2.to(settings.current, {
                        ease: 'expo.out',
                        duration: 10,
                        progress: 2,
                    })
                        .to(
                            titleAnimRef.current,
                            {
                                ease: 'expo.out',
                                duration: 0.8,
                                width: 0,
                                opacity: 0,
                            },
                            0
                        )
                        .to(
                            titleRef.current,
                            {
                                delay: 1,
                                duration: 0.6,
                                opacity: 1,
                                onComplete: () => {
                                    window.cancelAnimationFrame(animateFrame);
                                    mountRef.current.remove();
                                },
                            },
                            0
                        );
                    setHeaderAnimation(true);
                },
            });

            gsap.to(titleAnimRef.current, {
                ease: 'expo.out',
                duration: 1,
                width: '100%',
                progress: 0.018,
            });

            tl.to(
                [
                    scene.children[1].scale,
                    scene.children[2].scale,
                    settings.current,
                ],
                {
                    ease: 'expo.bounce',
                    duration: 0.8,
                    x: 1.1,
                    y: 1.1,
                    z: 1.1,
                    progress: 0.018,
                }
            ).to(
                [
                    scene.children[1].scale,
                    scene.children[2].scale,
                    settings.current,
                ],
                {
                    ease: 'expo.out',
                    duration: 1.3,
                    x: 1,
                    y: 1,
                    z: 1,
                    progress: 0,
                }
            );
        } else if (isMobile) {
            gsap.to(titleRef.current, {
                delay: 1,
                duration: 0.6,
                opacity: 1,
            });
        }
    }, [init]);

    useEffect(() => {
        if (!isMobile) {
            const renderer = rendererRef.current;
            const camera = cameraRef.current;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
        }
    }, [height, width]);

    return (
        <section className="hero-banner" datanav="black">
            <div className="container">
                <div className="wrapper-banner">
                    <h1 className="title-banner" ref={titleRef}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: intl.formatMessage({
                                    id: 'hero.title',
                                }),
                            }}
                        />
                        <span className="dot">.</span>
                    </h1>

                    <div className="wrap-title-code">
                        <h2 className="title-animation" ref={titleAnimRef}>
                            <span className="title-code">CODE</span>{' '}
                            <span className="title-soul">SOUL</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    zIndex: -1,
                }}
                ref={mountRef}
            />
        </section>
    );
};

export default HeroBanner;
