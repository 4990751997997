import React from 'react'

const cloud = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 232 128"
    >
        <g
            id="Grupo_1465"
            data-name="Grupo 1465"
            transform="translate(-278 -4409.049)"
        >
            <rect
                id="Retângulo_126"
                data-name="Retângulo 126"
                width="58"
                height="58"
                transform="translate(304 4440.049)"
                fill="#00B01E"
            />
            <rect
                id="Retângulo_127"
                data-name="Retângulo 127"
                width="29"
                height="38"
                transform="translate(343 4472.049)"
                fill="#151515"
            />
            <rect
                id="Retângulo_131"
                data-name="Retângulo 131"
                width="55"
                height="55"
                transform="translate(420 4440.049)"
                fill="#00B01E"
            />
            <rect
                id="Retângulo_132"
                data-name="Retângulo 132"
                width="25"
                height="27"
                transform="translate(428 4448.049)"
                fill="#151515"
            />
            <rect
                id="Retângulo_133"
                data-name="Retângulo 133"
                width="25"
                height="27"
                transform="translate(458 4448.049)"
                fill="#151515"
            />
            <rect
                id="Retângulo_134"
                data-name="Retângulo 134"
                width="25"
                height="27"
                transform="translate(428 4480.049)"
                fill="#151515"
            />
            <rect
                id="Retângulo_135"
                data-name="Retângulo 135"
                width="25"
                height="27"
                transform="translate(458 4480.049)"
                fill="#151515"
            />
            <g
                id="Elipse_24"
                data-name="Elipse 24"
                transform="translate(278 4409.049)"
                fill="none"
                stroke="#151515"
                strokeWidth="1"
                strokeDasharray="6"
            >
                <circle cx="64" cy="64" r="64" stroke="none" />
                <circle cx="64" cy="64" r="63.5" fill="none" />
            </g>
            <g
                id="Elipse_25"
                data-name="Elipse 25"
                transform="translate(382 4409.049)"
                fill="none"
                stroke="#151515"
                strokeWidth="1"
                strokeDasharray="6"
            >
                <circle cx="64" cy="64" r="64" stroke="none" />
                <circle cx="64" cy="64" r="63.5" fill="none" />
            </g>
        </g>
    </svg>
)

export default cloud
