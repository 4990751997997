import React from 'react'

const platforms = props => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143 81">
        <g
            id="Grupo_2560"
            data-name="Grupo 2560"
            transform="translate(3603 -1525)"
        >
            <g
                id="Grupo_2556"
                data-name="Grupo 2556"
                transform="translate(-4704 -2416.665)"
            >
                <rect
                    id="Retângulo_118"
                    data-name="Retângulo 118"
                    width="50"
                    height="50"
                    transform="translate(1106 3953.665)"
                    fill="#00b01e"
                />
                <g
                    id="Retângulo_137"
                    data-name="Retângulo 137"
                    transform="translate(1114 3963.665)"
                    fill="none"
                    stroke="#000"
                    strokeWidth="1"
                    strokeDasharray="6"
                >
                    <rect width="45" height="46" stroke="none" />
                    <rect x="0.5" y="0.5" width="44" height="45" fill="none" />
                </g>
                <g
                    id="Grupo_1460"
                    data-name="Grupo 1460"
                    transform="translate(1121.119 3969.624)"
                >
                    <rect
                        id="Retângulo_119"
                        data-name="Retângulo 119"
                        width="19"
                        height="22"
                        transform="translate(-0.119 0.041)"
                        fill="#151515"
                    />
                    <rect
                        id="Retângulo_120"
                        data-name="Retângulo 120"
                        width="20"
                        height="22"
                        transform="translate(23.881 0.041)"
                        fill="#151515"
                    />
                    <rect
                        id="Retângulo_121"
                        data-name="Retângulo 121"
                        width="19"
                        height="22"
                        transform="translate(-0.119 25.041)"
                        fill="#151515"
                    />
                    <rect
                        id="Retângulo_122"
                        data-name="Retângulo 122"
                        width="20"
                        height="22"
                        transform="translate(23.881 25.041)"
                        fill="#151515"
                    />
                </g>
            </g>
            <rect
                id="Retângulo_358"
                data-name="Retângulo 358"
                width="143"
                height="81"
                transform="translate(-3603 1525)"
                fill="none"
            />
        </g>
    </svg>
)

export default platforms
