import React from 'react'

const ux = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 143 81"
    >
        <g
            id="Grupo_2562"
            data-name="Grupo 2562"
            transform="translate(3405 -1274)"
        >
            <g
                id="Grupo_2553"
                data-name="Grupo 2553"
                transform="translate(-3679.391 -2153.605)"
            >
                <path
                    id="Caminho_3539"
                    data-name="Caminho 3539"
                    d="M32.1-.106,64.709,51.453.027,51.269Z"
                    transform="translate(278.364 3450.267)"
                    fill="#00b01e"
                />
                <ellipse
                    id="Elipse_19"
                    data-name="Elipse 19"
                    cx="27.973"
                    cy="25.779"
                    rx="27.973"
                    ry="25.779"
                    transform="translate(304.373 3450.6)"
                    fill="#151515"
                />
                <g
                    id="Elipse_20"
                    data-name="Elipse 20"
                    transform="translate(314.831 3450.6)"
                    fill="none"
                    stroke="#000"
                    strokeWidth="1"
                    strokeDasharray="6"
                >
                    <ellipse
                        cx="27.973"
                        cy="25.779"
                        rx="27.973"
                        ry="25.779"
                        stroke="none"
                    />
                    <ellipse
                        cx="27.973"
                        cy="25.779"
                        rx="27.473"
                        ry="25.279"
                        fill="none"
                    />
                </g>
            </g>
            <rect
                id="Retângulo_354"
                data-name="Retângulo 354"
                width="143"
                height="81"
                transform="translate(-3405 1274)"
                fill="none"
            />
        </g>
    </svg>
)

export default ux
