import React from 'react'

const fca = ({ bgColor = '#1f1e1d', darkMode = false }) => {
    if (darkMode) {
        bgColor = '#fff'
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="54"
            viewBox="0 0 101 54"
        >
            <g
                id="Grupo_2528"
                data-name="Grupo 2528"
                transform="translate(2391 -2909)"
            >
                <rect
                    id="Retângulo_349"
                    data-name="Retângulo 349"
                    width="101"
                    height="54"
                    transform="translate(-2391 2909)"
                    fill="#fff"
                    opacity="0"
                />
                <g
                    id="Grupo_1451"
                    data-name="Grupo 1451"
                    transform="translate(-2379 2922)"
                >
                    <path
                        id="Caminho_747"
                        data-name="Caminho 747"
                        d="M90.216,395.534v-.353h-2.4v3.359h.391v-1.451h1.786v-.344H88.206v-1.21Z"
                        transform="translate(-87.815 -369.949)"
                        fill={bgColor}
                    />
                    <rect
                        id="Retângulo_61"
                        data-name="Retângulo 61"
                        width="0.391"
                        height="3.359"
                        transform="translate(3.368 25.231)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_748"
                        data-name="Caminho 748"
                        d="M157.065,395.181v.353h1.116v3.005h.381v-3.005h1.1v-.353Z"
                        transform="translate(-149.334 -369.949)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_749"
                        data-name="Caminho 749"
                        d="M518.4,395.264v.353h1.116v3.005h.381v-3.005H521v-.353Z"
                        transform="translate(-470.328 -370.023)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_750"
                        data-name="Caminho 750"
                        d="M234.011,395.181v1.5h-1.9v-1.5h-.381v3.359h.381v-1.516h1.9v1.516h.382v-3.359Z"
                        transform="translate(-215.665 -369.949)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_751"
                        data-name="Caminho 751"
                        d="M352.123,398.186v-3.005h-.391v3.359h2.233v-.354Z"
                        transform="translate(-322.268 -369.949)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_752"
                        data-name="Caminho 752"
                        d="M706.539,398.222v-3h-.391v3.359h2.233v-.354Z"
                        transform="translate(-637.116 -369.981)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_753"
                        data-name="Caminho 753"
                        d="M378.688,398.186v-1.163h1.8v-.344h-1.8v-1.144h2.01v-.353h-2.382v3.359h2.419v-.354Z"
                        transform="translate(-345.883 -369.949)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_754"
                        data-name="Caminho 754"
                        d="M733.855,398.269v-1.163h1.8v-.344h-1.8v-1.144h2.01v-.353h-2.382v3.359H735.9v-.354Z"
                        transform="translate(-661.398 -370.023)"
                        fill={bgColor}
                    />
                    <rect
                        id="Retângulo_62"
                        data-name="Retângulo 62"
                        width="0.381"
                        height="3.368"
                        transform="translate(67.461 25.231)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_755"
                        data-name="Caminho 755"
                        d="M129.762,395.181H129.4l-1.5,3.359h.4l.389-.875H130.5l.383.875h.419Zm-.919,2.14.756-1.7.746,1.7Z"
                        transform="translate(-123.426 -369.95)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_756"
                        data-name="Caminho 756"
                        d="M455.261,395.264H454.9l-1.5,3.359h.4l.389-.875h1.806l.383.875h.419Zm-.919,2.14.756-1.7.746,1.7Z"
                        transform="translate(-412.585 -370.023)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_757"
                        data-name="Caminho 757"
                        d="M199.868,397.389l.276.254a1.693,1.693,0,0,1-1.284.592,1.735,1.735,0,0,1,0-3.47,1.691,1.691,0,0,1,1.243.546l-.253.263a1.356,1.356,0,1,0-.994,2.312A1.4,1.4,0,0,0,199.868,397.389Z"
                        transform="translate(-184.944 -369.579)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_758"
                        data-name="Caminho 758"
                        d="M267.7,397.181s.921-.149.9-.977a1,1,0,0,0-1.075-1.023h-1.544v3.359h.377v-1.293h.935l.958,1.293h.465Zm-1.349-.288V395.53h1.116s.754.014.744.642c0,0,.1.656-.8.721Z"
                        transform="translate(-246.092 -369.95)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_759"
                        data-name="Caminho 759"
                        d="M410.454,397.181s.921-.149.9-.977a1,1,0,0,0-1.074-1.023h-1.544v3.359h.377v-1.293h.935L411,398.54h.465Zm-1.349-.288V395.53h1.116s.754.014.744.642c0,0,.1.656-.8.721Z"
                        transform="translate(-372.905 -369.95)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_760"
                        data-name="Caminho 760"
                        d="M291.4,395.181h.456l1.116,1.679,1.107-1.679h.433l-1.368,2.033v1.326h-.368v-1.326Z"
                        transform="translate(-268.672 -369.949)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_761"
                        data-name="Caminho 761"
                        d="M323.685,395.434l.214-.312a1.786,1.786,0,0,0-1.07-.4c-.623,0-1.2.27-1.172,1.037s.958.763,1.656,1.033a.49.49,0,0,1,.316.456c-.028.321-.223.614-.819.586a1.8,1.8,0,0,1-1.051-.479l-.233.288a1.842,1.842,0,0,0,1.349.54A.975.975,0,0,0,324,397.2c0-.4-.214-.707-1.019-.916s-.949-.307-.949-.74S322.866,394.774,323.685,395.434Z"
                        transform="translate(-295.434 -369.542)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_762"
                        data-name="Caminho 762"
                        d="M764.367,395.73l.214-.312a1.786,1.786,0,0,0-1.07-.4c-.623,0-1.205.27-1.172,1.037s.958.763,1.656,1.033a.49.49,0,0,1,.316.456c-.028.321-.223.614-.819.586a1.8,1.8,0,0,1-1.051-.479l-.232.288a1.842,1.842,0,0,0,1.349.54.976.976,0,0,0,1.126-.982c0-.4-.214-.707-1.019-.916s-.949-.307-.949-.74S763.549,395.07,764.367,395.73Z"
                        transform="translate(-686.917 -369.805)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_763"
                        data-name="Caminho 763"
                        d="M487.858,395.181h.372v2.01a.994.994,0,0,0,1.037,1.051.946.946,0,0,0,.968-1v-2.061h.372v1.991a1.336,1.336,0,0,1-1.375,1.433,1.306,1.306,0,0,1-1.375-1.4Z"
                        transform="translate(-443.196 -369.95)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_764"
                        data-name="Caminho 764"
                        d="M545.525,394.677a1.74,1.74,0,1,0,1.684,1.74A1.671,1.671,0,0,0,545.525,394.677Zm.012,3.131a1.394,1.394,0,0,1,0-2.782,1.316,1.316,0,0,1,1.3,1.391A1.349,1.349,0,0,1,545.536,397.808Z"
                        transform="translate(-492.929 -369.502)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_765"
                        data-name="Caminho 765"
                        d="M621.421,394.936a1.741,1.741,0,1,0,1.684,1.74A1.671,1.671,0,0,0,621.421,394.936Zm.012,3.131a1.394,1.394,0,0,1,0-2.782,1.316,1.316,0,0,1,1.3,1.391A1.349,1.349,0,0,1,621.433,398.067Z"
                        transform="translate(-560.352 -369.732)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_766"
                        data-name="Caminho 766"
                        d="M585.2,395.181l-1.223,1.814-1.205-1.814H582.4v3.368h.372v-2.7l1.205,1.777,1.223-1.782v2.7h.372v-3.368Z"
                        transform="translate(-527.182 -369.949)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_767"
                        data-name="Caminho 767"
                        d="M661.176,396.809a.879.879,0,0,0,.507-.842c-.032-.614-.7-.78-1.028-.786h-1.461v3.368h1.572s1.075-.019,1.1-.912A.844.844,0,0,0,661.176,396.809Zm-1.619-1.289H660.6c.763.019.7.553.7.553,0,.586-.651.6-.651.6h-1.093Zm1.214,2.675h-1.214v-1.177h1.163s.791,0,.772.588S660.771,398.195,660.771,398.195Z"
                        transform="translate(-595.403 -369.95)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_768"
                        data-name="Caminho 768"
                        d="M168.69,199.82h3.847v-6.14H184.93v-3.573H172.482v-4.187H185.53v-3.74H168.69Z"
                        transform="translate(-159.661 -180.729)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_769"
                        data-name="Caminho 769"
                        d="M506.648,188.272H510.8l7.012-11.946,6.866,11.946h4.242l-11.136-19.091Z"
                        transform="translate(-459.889 -169.181)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_770"
                        data-name="Caminho 770"
                        d="M355,186.711a6.088,6.088,0,1,1-.019-3.684h3.685a9.619,9.619,0,1,0,.011,3.684Z"
                        transform="translate(-311.31 -174.597)"
                        fill={bgColor}
                    />
                </g>
            </g>
        </svg>
    )
}

export default fca
