import React from 'react'

const JohnsonControls = ({ bgColor = '#1f1e1d', darkMode = false }) => {
    if (darkMode) {
        bgColor = '#fff'
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="101"
            height="54"
            viewBox="0 0 101 54"
        >
            <defs>
                <clipPath id="clip-path">
                    <path
                        id="Caminho_783"
                        data-name="Caminho 783"
                        d="M776.793,427.709H850.9v32.706H776.793V427.709"
                        transform="translate(-776.793 -427.709)"
                        fill="#1f1e1d"
                    />
                </clipPath>
            </defs>
            <g
                id="Grupo_2532"
                data-name="Grupo 2532"
                transform="translate(2594 -2909)"
            >
                <rect
                    id="Retângulo_347"
                    data-name="Retângulo 347"
                    width="101"
                    height="54"
                    transform="translate(-2594 2909)"
                    fill="#fff"
                    opacity="0"
                />
                <g
                    id="Grupo_1455"
                    data-name="Grupo 1455"
                    transform="translate(-2581 2920)"
                >
                    <path
                        id="Caminho_779"
                        data-name="Caminho 779"
                        d="M819.49,548.565a4.205,4.205,0,0,1-4.381-4.427c0-2.535,1.576-4.453,4.179-4.453a6.407,6.407,0,0,1,2.467.582l-.514,1.284a4.9,4.9,0,0,0-1.98-.49c-1.65,0-2.476,1.4-2.476,2.926,0,1.841.976,3.2,2.905,3.2a3.932,3.932,0,0,0,1.69-.4l.4,1.211a5.21,5.21,0,0,1-2.29.569"
                        transform="translate(-806.981 -515.933)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_780"
                        data-name="Caminho 780"
                        d="M850.752,551.876c-1.115,0-1.478,1.037-1.478,1.994s.364,1.981,1.478,1.981,1.479-1.022,1.479-1.981-.364-1.994-1.479-1.994m0,5.274a3.3,3.3,0,1,1,3.142-3.292A3.076,3.076,0,0,1,850.752,557.15Z"
                        transform="translate(-832.589 -524.505)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_781"
                        data-name="Caminho 781"
                        d="M885.219,557.008v-3.959a1.1,1.1,0,0,0-1.241-1.159,3.672,3.672,0,0,0-1.265.276,2.21,2.21,0,0,1,.026.265v4.577h-1.5v-4.93a8.181,8.181,0,0,0-.089-.884,8.943,8.943,0,0,1,2.842-.631c1.528,0,2.727.5,2.729,2.246v4.2h-1.5"
                        transform="translate(-859.013 -524.504)"
                        fill={bgColor}
                    />
                    <g
                        id="Grupo_1454"
                        data-name="Grupo 1454"
                        transform="translate(0 0)"
                    >
                        <g
                            id="Grupo_1453"
                            data-name="Grupo 1453"
                            transform="translate(0 0)"
                            clipPath="url(#clip-path)"
                        >
                            <path
                                id="Caminho_782"
                                data-name="Caminho 782"
                                d="M913.951,551.346c-1.14,0-1.928-.528-1.928-1.752v-3.481l-.712-.038v-1.2l.712-.039v-1.75h1.5v1.725h1.753v1.313h-1.753v3.353c0,.391.2.543.576.543a3.359,3.359,0,0,0,1.115-.253l.413,1.224a5.1,5.1,0,0,1-1.677.351"
                                transform="translate(-882.777 -518.616)"
                                fill={bgColor}
                            />
                        </g>
                    </g>
                    <path
                        id="Caminho_784"
                        data-name="Caminho 784"
                        d="M937.93,552a3.142,3.142,0,0,0-.827-.128,3.855,3.855,0,0,0-1.127.165s.012.089.012.127v4.842h-1.5v-4.8a7.489,7.489,0,0,0-.115-.945,7.5,7.5,0,0,1,2.856-.7,4.817,4.817,0,0,1,1.15.14l-.449,1.3"
                        transform="translate(-900.948 -524.505)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_785"
                        data-name="Caminho 785"
                        d="M956.1,551.876c-1.113,0-1.477,1.036-1.477,1.994s.364,1.981,1.477,1.981,1.479-1.022,1.479-1.979-.364-2-1.479-2m0,5.274a3.3,3.3,0,1,1,3.142-3.292A3.073,3.073,0,0,1,956.1,557.15Z"
                        transform="translate(-915.592 -524.505)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_786"
                        data-name="Caminho 786"
                        d="M988.372,537.305h-1.5v9.258h1.5v-9.258"
                        transform="translate(-942.312 -514.058)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_787"
                        data-name="Caminho 787"
                        d="M1000.032,557.15a5.914,5.914,0,0,1-2.065-.416l.435-1.187a4.174,4.174,0,0,0,1.5.378c.462,0,.949-.137.949-.693,0-1.122-2.73-.782-2.73-2.849,0-1.3,1.331-1.817,2.318-1.817a4.39,4.39,0,0,1,1.8.379l-.424,1.173a4.445,4.445,0,0,0-1.379-.292c-.375,0-.775.115-.775.568,0,1.061,2.853.581,2.853,2.861,0,1.4-1.35,1.895-2.49,1.895"
                        transform="translate(-951.052 -524.505)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_788"
                        data-name="Caminho 788"
                        d="M778.434,495.6c-.357.124-.894.273-1.367.394l-.275-1.362a6.327,6.327,0,0,0,.934-.225c.865-.307.94-.936.94-1.949v-6.431h1.555v6.188c0,1.7-.245,2.83-1.788,3.386"
                        transform="translate(-776.792 -473.657)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_789"
                        data-name="Caminho 789"
                        d="M800.517,497.285c-1.11,0-1.475,1.033-1.475,1.995s.365,1.978,1.475,1.978,1.48-1.02,1.48-1.978-.363-1.995-1.48-1.995m0,5.27a3.294,3.294,0,1,1,3.145-3.289A3.074,3.074,0,0,1,800.517,502.555Z"
                        transform="translate(-793.011 -481.493)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_790"
                        data-name="Caminho 790"
                        d="M835.6,491.968v-3.984a1.041,1.041,0,0,0-1.049-1.147,2.956,2.956,0,0,0-1.444.465v4.666h-1.5v-9.256h1.5v3.228a4.722,4.722,0,0,1,1.856-.416,1.978,1.978,0,0,1,2.136,2.233v4.21h-1.5"
                        transform="translate(-819.978 -471.045)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_791"
                        data-name="Caminho 791"
                        d="M867.182,502.424v-3.957a1.1,1.1,0,0,0-1.237-1.162,3.681,3.681,0,0,0-1.266.279,2.407,2.407,0,0,1,.027.265v4.575h-1.5v-4.93a8.134,8.134,0,0,0-.089-.883,8.932,8.932,0,0,1,2.843-.627c1.527,0,2.728.5,2.728,2.242v4.2h-1.5"
                        transform="translate(-844.804 -481.502)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_792"
                        data-name="Caminho 792"
                        d="M895.528,502.555a5.9,5.9,0,0,1-2.067-.416l.439-1.184a4.194,4.194,0,0,0,1.5.379c.464,0,.954-.14.954-.693,0-1.125-2.731-.784-2.731-2.854,0-1.3,1.326-1.812,2.317-1.814a4.357,4.357,0,0,1,1.8.379l-.424,1.174a4.292,4.292,0,0,0-1.376-.293c-.379,0-.776.114-.776.569,0,1.059,2.853.581,2.853,2.863,0,1.4-1.352,1.892-2.493,1.89"
                        transform="translate(-868.714 -481.493)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_793"
                        data-name="Caminho 793"
                        d="M921.227,497.283c-1.115,0-1.476,1.034-1.476,1.994s.361,1.978,1.476,1.978,1.476-1.02,1.476-1.978-.362-2-1.476-1.994m0,5.272a3.3,3.3,0,1,1,3.143-3.291A3.078,3.078,0,0,1,921.227,502.555Z"
                        transform="translate(-888.112 -481.492)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_794"
                        data-name="Caminho 794"
                        d="M956.013,502.416v-3.957a1.1,1.1,0,0,0-1.238-1.162,3.691,3.691,0,0,0-1.264.279,2.418,2.418,0,0,1,.027.265v4.575h-1.5v-4.93a8.121,8.121,0,0,0-.089-.884,8.927,8.927,0,0,1,2.837-.629c1.527,0,2.728.5,2.728,2.244v4.2h-1.5"
                        transform="translate(-914.796 -481.493)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_795"
                        data-name="Caminho 795"
                        d="M1116.855,470.335a7.462,7.462,0,0,0-1.27,8.606,11.825,11.825,0,0,0,1.27-8.606"
                        transform="translate(-1043.058 -461.293)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_796"
                        data-name="Caminho 796"
                        d="M1019.7,443.1a11.774,11.774,0,0,0-3.543,7.652,13.57,13.57,0,0,0,3.543-7.652"
                        transform="translate(-965.385 -439.834)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_797"
                        data-name="Caminho 797"
                        d="M1022.306,434.887c-.181.1-.358.215-.533.328-1.2,5.685-2.706,8.512-5.231,11.5a11.571,11.571,0,0,0,.372,1.739c4.774-2.958,5.782-9.554,5.393-13.566"
                        transform="translate(-965.687 -433.364)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_798"
                        data-name="Caminho 798"
                        d="M1059.578,437.145a28.137,28.137,0,0,0-1.96-9.284c-.222-.032-.449-.059-.676-.079,1.071,9.142.243,17.307-5.153,22.746a11.512,11.512,0,0,0,2.94.688c3.187-2.82,5.133-7.5,4.85-14.072"
                        transform="translate(-993.458 -427.766)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_799"
                        data-name="Caminho 799"
                        d="M1040.607,427.709c-.223.016-.444.038-.663.067.216,6.036-.535,15.254-6.458,20.267a11.806,11.806,0,0,0,1.715,1.338c7.379-5.053,7.343-14.937,5.406-21.672"
                        transform="translate(-979.037 -427.709)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_800"
                        data-name="Caminho 800"
                        d="M1029.016,429.994c-.2.06-.39.125-.58.195-.354,6.308-1.711,12.141-6.384,16.413a11.808,11.808,0,0,0,1.005,1.635c6.042-3.675,7.278-12.385,5.959-18.242"
                        transform="translate(-970.029 -429.509)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_801"
                        data-name="Caminho 801"
                        d="M1091.51,448.9a12.473,12.473,0,0,1,3.787-5.921q-.26-.277-.536-.536a14.5,14.5,0,0,0-3.739,2.58,25.665,25.665,0,0,1,.487,3.878"
                        transform="translate(-1024.37 -439.321)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_802"
                        data-name="Caminho 802"
                        d="M1085.369,504.208a11.632,11.632,0,0,0,2.7,2.714,11.823,11.823,0,0,0,1.1-.708,13.677,13.677,0,0,1-2.511-5.893,16.445,16.445,0,0,1-1.285,3.886"
                        transform="translate(-1019.915 -484.92)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_803"
                        data-name="Caminho 803"
                        d="M1087.015,435.806a24.41,24.41,0,0,1,2.028-2.241c-.211-.121-.425-.237-.643-.345a16.434,16.434,0,0,0-1.877.946q.266.8.493,1.641"
                        transform="translate(-1020.823 -432.051)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_804"
                        data-name="Caminho 804"
                        d="M1101.008,455.226c-.118-.231-.242-.459-.374-.681a9.029,9.029,0,0,0-4.745,7.113,9.149,9.149,0,0,0,2.865,7.37,11.8,11.8,0,0,0,.891-.975,11.284,11.284,0,0,1,1.362-12.827"
                        transform="translate(-1028.178 -448.853)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_805"
                        data-name="Caminho 805"
                        d="M1077.951,527.72a11.639,11.639,0,0,0,1.44-.385,20.8,20.8,0,0,1-1.665-2.184,13.274,13.274,0,0,1-1.087,1.5,16.34,16.34,0,0,0,1.312,1.071"
                        transform="translate(-1013.037 -504.482)"
                        fill={bgColor}
                    />
                </g>
            </g>
        </svg>
    )
}
export default JohnsonControls
