import React from 'react'

const iot = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 143 81"
    >
        <g
            id="Grupo_2559"
            data-name="Grupo 2559"
            transform="translate(3598 -1422)"
        >
            <g
                id="Grupo_2555"
                data-name="Grupo 2555"
                transform="translate(-3872 -2504)"
            >
                <path
                    id="Polígono_9"
                    data-name="Polígono 9"
                    d="M25.5,0,51,22H0Z"
                    transform="translate(278 3950)"
                    fill="#151515"
                />
                <g
                    id="Retângulo_136"
                    data-name="Retângulo 136"
                    transform="translate(307 3962)"
                    fill="none"
                    stroke="#000"
                    strokeWidth="1"
                    strokeDasharray="6"
                >
                    <rect width="39" height="39" stroke="none" />
                    <rect x="0.5" y="0.5" width="38" height="38" fill="none" />
                </g>
                <rect
                    id="Retângulo_117"
                    data-name="Retângulo 117"
                    width="51"
                    height="26"
                    transform="translate(278 3972)"
                    fill="#00b01e"
                />
            </g>
            <rect
                id="Retângulo_357"
                data-name="Retângulo 357"
                width="143"
                height="81"
                transform="translate(-3598 1422)"
                fill="none"
            />
        </g>
    </svg>
)

export default iot
