import React, { useEffect, useRef } from 'react';

import { TimelineLite, Expo } from 'gsap';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { injectIntl } from 'gatsby-plugin-intl';

const ContinuousSatisfaction = ({ intl }) => {
    let container = useRef();

    useEffect(() => {
        const timeLineAnimation = new TimelineLite({
            paused: true,
            delay: 0.4,
        });

        timeLineAnimation.to(
            container,
            0.3,
            {
                // left: '100%',
                opacity: 1,
                ease: Expo.easeIn,
            },
            1
        );

        // eslint-disable-next-line no-undef
        const intersectionObserver = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        timeLineAnimation.play();
                    }
                });
            }
        );

        intersectionObserver.observe(container);
    }, []);

    const data = useStaticQuery(graphql`
        query {
            file(
                relativePath: { eq: "satisfaction/continuous-satisfaction.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <section className="continuous-satisfaction" datanav="black">
            <div className="container" ref={(el) => (container = el)}>
                <h2 className="title">
                    {intl.formatMessage({
                        id: 'continuous.title',
                    })}
                </h2>

                <div className="content">
                    <div className="wrap-methodology">
                        <div>
                            <h3 className="title-with-subtitle">
                                {intl.formatMessage({
                                    id: 'continuous.with',
                                })}
                            </h3>
                            <span className="subtitle">
                                {intl.formatMessage({
                                    id: 'continuous.subtitle',
                                })}
                            </span>
                        </div>
                    </div>

                    <div className="wrap-image">
                        <Img
                            fluid={data.file.childImageSharp.fluid}
                            className="img-methodology"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default injectIntl(ContinuousSatisfaction);
