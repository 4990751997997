import React from 'react'

const Firstoeat = ({ bgColor = '#1f1e1d', darkMode = false }) => {
    if (darkMode) {
        bgColor = '#fff'
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="102"
            height="54"
            viewBox="0 0 102 54"
        >
            <g
                id="Grupo_2535"
                data-name="Grupo 2535"
                transform="translate(2493 -2909)"
            >
                <rect
                    id="Retângulo_348"
                    data-name="Retângulo 348"
                    width="102"
                    height="54"
                    transform="translate(-2493 2909)"
                    fill="#fff"
                    opacity="0"
                />
                <g
                    id="Grupo_119"
                    data-name="Grupo 119"
                    transform="translate(-2487 2924)"
                >
                    <g
                        id="Grupo_116"
                        data-name="Grupo 116"
                        transform="translate(0 0)"
                    >
                        <g
                            id="Grupo_115"
                            data-name="Grupo 115"
                            transform="translate(0 0)"
                        >
                            <path
                                id="Caminho_280"
                                data-name="Caminho 280"
                                d="M1511.462,1059.4a10.652,10.652,0,0,1-4.024,12.727c-7.159,5.191-17.8,4.59-19.347-4.139,0,0-.173,8.154,9.222,8.742C1506.112,1077.275,1515.4,1069.391,1511.462,1059.4Z"
                                transform="translate(-1488.091 -1052.152)"
                                fill={bgColor}
                            />
                            <g
                                id="Grupo_113"
                                data-name="Grupo 113"
                                transform="translate(9.224 6.157)"
                            >
                                <path
                                    id="Caminho_281"
                                    data-name="Caminho 281"
                                    d="M1599.489,1049.692a18.653,18.653,0,0,0-3.131,3.693c-.073.262.083.424.208.655a.693.693,0,0,1,.157.675,3.507,3.507,0,0,1-.573.9c-.271.325-2.742,3.165-3.5,4.182a.925.925,0,0,0-.089.145l-.168.342c-.191.037.469,1.033,1.376.411.624-.92,2.792-3.452,3.411-4.323.7-.986,1.38-1.951,2.131-2.931.278-.365,1.447-1.894,2.535-3.333s2.1-2.812,2.131-2.931C1602.983,1046.79,1601.147,1048.135,1599.489,1049.692Z"
                                    transform="translate(-1592.358 -1047.109)"
                                    fill={bgColor}
                                />
                            </g>
                            <path
                                id="Caminho_282"
                                data-name="Caminho 282"
                                d="M1610.4,1163.594l.564-.531-.708,2.782Z"
                                transform="translate(-1599.444 -1146.649)"
                                fill={bgColor}
                            />
                            <g
                                id="Grupo_114"
                                data-name="Grupo 114"
                                transform="translate(9.12 3.107)"
                            >
                                <path
                                    id="Caminho_283"
                                    data-name="Caminho 283"
                                    d="M1594.478,1020.31a2.586,2.586,0,0,0,1.64-2.022l.614-4.864a.14.14,0,0,0-.118-.137h0a.124.124,0,0,0-.161.074l-1.115,5.215c-.016.073-.113.11-.218.084s-.179-.106-.165-.179l.975-5.2a.2.2,0,0,0-.167-.2h0a.177.177,0,0,0-.23.106l-1.086,5.073c-.016.073-.113.11-.219.084s-.179-.106-.165-.179l.955-5.105a.2.2,0,0,0-.167-.2.177.177,0,0,0-.229.106l-1.105,5.169c-.016.073-.113.11-.218.084s-.179-.106-.166-.179l.984-5.248a.139.139,0,0,0-.118-.143h0a.126.126,0,0,0-.16.069l-1.3,4.693a2.855,2.855,0,0,0,.712,2.6l.05.058-2.1,8.737c-.068.315.238.661.68.77s.851-.06.91-.376l1.624-8.856Z"
                                    transform="translate(-1591.187 -1012.634)"
                                    fill={bgColor}
                                />
                            </g>
                            <path
                                id="Caminho_284"
                                data-name="Caminho 284"
                                d="M1531.612,1026.422l-1.163,1.378c.494,2.867-1.122,6.191-4.251,8.19-3.927,2.508-8.746,1.98-10.764-1.179s-.471-7.754,3.456-10.262a10.27,10.27,0,0,1,2.679-1.218l.25-1.033a12,12,0,0,0-3.464,1.516c-4.614,2.947-6.432,8.346-4.061,12.059s8.035,4.333,12.649,1.385C1531.073,1034.621,1532.959,1030.021,1531.612,1026.422Z"
                                transform="translate(-1511.027 -1018.337)"
                                fill={bgColor}
                            />
                            <rect
                                id="Retângulo_53"
                                data-name="Retângulo 53"
                                width="0.845"
                                height="1.971"
                                rx="0.422"
                                transform="matrix(0.75, -0.661, 0.661, 0.75, 3.467, 4.038)"
                                fill={bgColor}
                            />
                            <rect
                                id="Retângulo_54"
                                data-name="Retângulo 54"
                                width="0.845"
                                height="1.971"
                                rx="0.422"
                                transform="translate(13.715 0) rotate(11.184)"
                                fill={bgColor}
                            />
                            <rect
                                id="Retângulo_55"
                                data-name="Retângulo 55"
                                width="0.845"
                                height="1.971"
                                rx="0.422"
                                transform="matrix(0.738, 0.675, -0.675, 0.738, 22.176, 3.697)"
                                fill={bgColor}
                            />
                        </g>
                    </g>
                    <g
                        id="Grupo_117"
                        data-name="Grupo 117"
                        transform="translate(27.306 7.827)"
                    >
                        <path
                            id="Caminho_285"
                            data-name="Caminho 285"
                            d="M1871.149,1070.128l-1.419,8.668h-2.695l1.419-8.668Zm-.7-3.985a1.157,1.157,0,0,1,1.276,1.222,1.642,1.642,0,0,1-1.743,1.671,1.153,1.153,0,0,1-1.276-1.2A1.627,1.627,0,0,1,1870.448,1066.143Z"
                            transform="translate(-1860.818 -1066.13)"
                            fill={bgColor}
                        />
                        <path
                            id="Caminho_286"
                            data-name="Caminho 286"
                            d="M1971.543,1110.951c-1.437-.413-2.443-1.06-2.443-2.479,0-1.922,1.581-3.18,3.881-3.18a6.945,6.945,0,0,1,2.821.557l-.359,2.3a5.956,5.956,0,0,0-2.174-.665,3.108,3.108,0,0,0-.826.036c-.431.072-.863.269-.863.719,0,.4.413.629,1.258.9,1.725.5,2.587,1.168,2.587,2.479,0,1.994-1.653,3.162-4.168,3.162a6.9,6.9,0,0,1-2.964-.593l.377-2.354a6.549,6.549,0,0,0,2.875.827c.719,0,1.4-.2,1.4-.791C1972.944,1111.436,1972.441,1111.239,1971.543,1110.951Z"
                            transform="translate(-1953.117 -1101.815)"
                            fill={bgColor}
                        />
                        <path
                            id="Caminho_287"
                            data-name="Caminho 287"
                            d="M2115.14,1109.352a5.5,5.5,0,0,1-5.605,5.426,3.886,3.886,0,0,1-4.1-4.06,5.492,5.492,0,0,1,5.587-5.426A3.9,3.9,0,0,1,2115.14,1109.352Zm-2.551.288a1.9,1.9,0,0,0-1.923-2.084,2.76,2.76,0,0,0-2.677,2.892,1.886,1.886,0,0,0,1.9,2.066A2.758,2.758,0,0,0,2112.589,1109.64Z"
                            transform="translate(-2078.131 -1101.815)"
                            fill={bgColor}
                        />
                        <path
                            id="Caminho_288"
                            data-name="Caminho 288"
                            d="M2226.971,1108.849a6.516,6.516,0,0,1-.305,1.743h-5.893c-.09,1.222.737,1.922,2.192,1.922a8.321,8.321,0,0,0,3.216-.665l-.378,2.282a8.515,8.515,0,0,1-3.449.647c-2.767,0-4.348-1.689-4.348-4.043a5.449,5.449,0,0,1,5.372-5.444A3.335,3.335,0,0,1,2226.971,1108.849Zm-3.935-1.437a2.149,2.149,0,0,0-2.048,1.8h3.4C2224.564,1108.238,2224.133,1107.412,2223.037,1107.412Z"
                            transform="translate(-2180.742 -1101.815)"
                            fill={bgColor}
                        />
                        <path
                            id="Caminho_289"
                            data-name="Caminho 289"
                            d="M2330.315,1114.526h-2.443l.071-.826a2.025,2.025,0,0,1,.144-.593h-.036a3.983,3.983,0,0,1-3.234,1.671c-1.994,0-3.467-1.492-3.467-3.917,0-3.414,2.407-5.57,4.994-5.57a2.742,2.742,0,0,1,2.677,1.617h.036a2.145,2.145,0,0,1,.072-.611l.162-.754h2.443Zm-6.4-4.06a1.821,1.821,0,0,0,1.8,2.048,2.717,2.717,0,0,0,2.587-2.911,1.824,1.824,0,0,0-1.814-2.048A2.705,2.705,0,0,0,2323.919,1110.466Z"
                            transform="translate(-2274.943 -1101.815)"
                            fill={bgColor}
                        />
                        <path
                            id="Caminho_290"
                            data-name="Caminho 290"
                            d="M1797.032,1078.661h2.677l1.007-6.432h1.906l.412-2.2h-2.1l.09-.483a1.586,1.586,0,0,1,1.725-1.381,2.813,2.813,0,0,1,.9.121l.359-2.192a6.02,6.02,0,0,0-1.168-.1c-2.426,0-3.935,1.294-4.258,3.261l-.125.777h-1.276l-.412,2.2h1.274Z"
                            transform="translate(-1796.765 -1065.994)"
                            fill={bgColor}
                        />
                        <path
                            id="Caminho_291"
                            data-name="Caminho 291"
                            d="M2439.953,1084.711l.431-2.606h2.605l-.431,2.606h2.39l-.323,2.1h-2.39l-.575,3.7c-.162.952.359,1.276.952,1.276a4.01,4.01,0,0,0,1.581-.378l-.36,2.21a5.941,5.941,0,0,1-2.335.413c-1.419,0-2.7-.665-2.605-2.551.036-.287.036-.593.09-.916l.594-3.753h-1.24l.324-2.1Z"
                            transform="translate(-2381.58 -1080.68)"
                            fill={bgColor}
                        />
                        <path
                            id="Caminho_292"
                            data-name="Caminho 292"
                            d="M1916.067,1108.408a3.2,3.2,0,0,0-.523-.039,3.844,3.844,0,0,0-2.928,1.666c0-.1.036-.312.072-.572l.09-.85h-2.444l-1.419,8.675h2.677l.611-3.7a3.264,3.264,0,0,1,2.307-2.662A2.912,2.912,0,0,1,1916.067,1108.408Z"
                            transform="translate(-1898.993 -1104.62)"
                            fill={bgColor}
                        />
                        <path
                            id="Caminho_293"
                            data-name="Caminho 293"
                            d="M2057.95,1091.834a2.889,2.889,0,0,1-.339.021c-.593,0-1.114-.323-.952-1.276l.574-3.7h1.011a5.425,5.425,0,0,1,1.695-2.055l.007-.047h-2.389l.431-2.607h-2.6l-.431,2.607h-1.294l-.323,2.1h1.24l-.593,3.754c-.054.323-.054.629-.09.916-.09,1.886,1.185,2.551,2.6,2.551a7.578,7.578,0,0,0,.817-.044h0a4.884,4.884,0,0,0,.59-.088c.182-.036.347-.077.493-.12a6.379,6.379,0,0,0,.872-.318A3.682,3.682,0,0,1,2057.95,1091.834Z"
                            transform="translate(-2030.638 -1080.74)"
                            fill={bgColor}
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Firstoeat
