import { useEffect, useRef, useState } from 'react';

const useResponsiveCanvas = (initialSize) => {
    const canvasRef = useRef();
    const mountRef = useRef();
    const [size, setSize] = useState([0, 0]);

    useEffect(() => {
        const canvas = document.createElement('canvas');
        const mount = mountRef.current;

        canvas.style.display = 'block';
        canvasRef.current = canvas;

        let width = 0;
        let height = 0;

        if (initialSize !== undefined) {
            [width, height] = initialSize;
        } else {
            width = mount.offsetWidth;
            height = mount.offsetHeight;
        }

        const resizeObserver = new ResizeObserver((entries) => {
            if (!entries || !entries.length) {
                return;
            }

            if (initialSize === undefined) {
                let { width, height } = entries[0].contentRect;
                setSize([width, height]);
            }
        });

        resizeObserver.observe(mount);
        // mount.appendChild(canvas);

        return () => {
            resizeObserver.unobserve(mount);
            // mount.removeChild(canvas);
        };
    }, [initialSize]);

    return {
        canvas: canvasRef.current,
        mountRef,
        size,
    };
};

export default useResponsiveCanvas;
