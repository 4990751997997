import React from 'react'
const Mube = ({
    bgColor = '#1f1e1d',
    hatColor = '#f2f2f2',
    darkMode = false,
}) => {
    if (darkMode) {
        bgColor = '#ffffff'
        hatColor = '#151515'
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="102"
            height="54"
            viewBox="0 0 102 54"
        >
            <g
                id="Grupo_2525"
                data-name="Grupo 2525"
                transform="translate(2493 -2801)"
            >
                <rect
                    id="Retângulo_342"
                    data-name="Retângulo 342"
                    width="102"
                    height="54"
                    transform="translate(-2493 2801)"
                    fill="#fff"
                    opacity="0"
                />
                <g
                    id="Grupo_149"
                    data-name="Grupo 149"
                    transform="translate(-2474 2814)"
                >
                    <path
                        id="Caminho_421"
                        data-name="Caminho 421"
                        d="M1121.4,3046.186h-1.354v1.056h1.514v.539h-2.17v-3.594h2.09v.539h-1.434v.927h1.354Z"
                        transform="translate(-1111.932 -3020.69)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_422"
                        data-name="Caminho 422"
                        d="M1125.9,3048.559a1.429,1.429,0,0,0,.661.191c.289,0,.417-.123.417-.293s-.107-.261-.427-.378c-.506-.171-.714-.448-.714-.757,0-.458.378-.811.981-.811a1.5,1.5,0,0,1,.693.155l-.133.458a1.15,1.15,0,0,0-.549-.149c-.234,0-.363.118-.363.277s.123.24.453.363c.475.165.688.405.693.789,0,.474-.368.816-1.056.816a1.665,1.665,0,0,1-.789-.182Z"
                        transform="translate(-1115.735 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_423"
                        data-name="Caminho 423"
                        d="M1132.034,3045.006v.667h.624v.49h-.624v1.146c0,.32.085.48.336.48a.853.853,0,0,0,.256-.026l.01.5a1.431,1.431,0,0,1-.474.07.752.752,0,0,1-.565-.213,1.023,1.023,0,0,1-.208-.731v-1.221h-.368v-.49h.368v-.485Z"
                        transform="translate(-1118.868 -3021.178)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_424"
                        data-name="Caminho 424"
                        d="M1138.051,3048.542a3.479,3.479,0,0,0,.042.624h-.592l-.048-.288h-.016a.948.948,0,0,1-.768.347.771.771,0,0,1-.816-.773c0-.65.581-.987,1.541-.981v-.043c0-.17-.069-.453-.528-.453a1.382,1.382,0,0,0-.7.192l-.128-.426a1.866,1.866,0,0,1,.939-.23.973.973,0,0,1,1.072,1.1Zm-.64-.645c-.464-.006-.906.09-.906.485a.344.344,0,0,0,.373.373.539.539,0,0,0,.517-.357.445.445,0,0,0,.016-.144Z"
                        transform="translate(-1121.75 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_425"
                        data-name="Caminho 425"
                        d="M1145.119,3048.787a1.507,1.507,0,0,1-.384,1.179,1.489,1.489,0,0,1-1,.314,1.9,1.9,0,0,1-.907-.208l.144-.5a1.566,1.566,0,0,0,.757.2.69.69,0,0,0,.746-.784v-.224h-.011a.87.87,0,0,1-.757.385,1.152,1.152,0,0,1-1.1-1.27,1.244,1.244,0,0,1,1.178-1.37.812.812,0,0,1,.752.405h.011l.027-.346h.57c-.01.176-.021.39-.021.747Zm-.656-1.178a.61.61,0,0,0-.021-.177.545.545,0,0,0-.533-.421c-.368,0-.64.32-.64.847,0,.443.224.8.635.8a.559.559,0,0,0,.533-.4.789.789,0,0,0,.026-.229Z"
                        transform="translate(-1125.773 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_426"
                        data-name="Caminho 426"
                        d="M1151.168,3044.333a.368.368,0,1,1-.368-.357A.35.35,0,0,1,1151.168,3044.333Zm-.7,3.322v-2.6h.656v2.6Z"
                        transform="translate(-1130.442 -3020.564)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_427"
                        data-name="Caminho 427"
                        d="M1155.654,3046.968a3.525,3.525,0,0,0,.042.624h-.592l-.048-.288h-.016a.948.948,0,0,1-.767.346.771.771,0,0,1-.816-.773c0-.651.581-.987,1.54-.981v-.043c0-.171-.069-.453-.527-.453a1.371,1.371,0,0,0-.7.192l-.128-.427a1.867,1.867,0,0,1,.938-.229.973.973,0,0,1,1.072,1.1Zm-.64-.645c-.464-.007-.906.09-.906.484a.345.345,0,0,0,.373.374.538.538,0,0,0,.517-.357.436.436,0,0,0,.016-.144Zm.4-2.454-.651.789h-.453l.464-.789Z"
                        transform="translate(-1132.244 -3020.5)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_428"
                        data-name="Caminho 428"
                        d="M1160.583,3047.406c0-.352-.005-.608-.021-.837h.565l.026.491h.016a.777.777,0,0,1,.715-.55.659.659,0,0,1,.155.016v.613a1.118,1.118,0,0,0-.2-.016.577.577,0,0,0-.587.5,1.106,1.106,0,0,0-.016.2v1.349h-.656Z"
                        transform="translate(-1136.48 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_429"
                        data-name="Caminho 429"
                        d="M1165.958,3044.333a.368.368,0,1,1-.368-.357A.35.35,0,0,1,1165.958,3044.333Zm-.7,3.322v-2.6h.656v2.6Z"
                        transform="translate(-1139.258 -3020.564)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_430"
                        data-name="Caminho 430"
                        d="M1170.92,3047.844a1.287,1.287,0,0,1-1.333,1.381,1.256,1.256,0,0,1-1.3-1.338,1.282,1.282,0,0,1,1.344-1.376A1.247,1.247,0,0,1,1170.92,3047.844Zm-1.957.027c0,.5.25.879.645.879.373,0,.635-.363.635-.89,0-.405-.181-.874-.629-.874C1169.15,3046.985,1168.963,3047.438,1168.963,3047.87Z"
                        transform="translate(-1141.085 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_431"
                        data-name="Caminho 431"
                        d="M1175.88,3048.559a1.428,1.428,0,0,0,.661.191c.288,0,.416-.123.416-.293s-.106-.261-.426-.378c-.506-.171-.715-.448-.715-.757,0-.458.379-.811.981-.811a1.493,1.493,0,0,1,.693.155l-.133.458a1.147,1.147,0,0,0-.549-.149c-.235,0-.363.118-.363.277s.123.24.453.363c.475.165.688.405.694.789,0,.474-.369.816-1.056.816a1.665,1.665,0,0,1-.789-.182Z"
                        transform="translate(-1145.533 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_432"
                        data-name="Caminho 432"
                        d="M1184.6,3048.051a.7.7,0,0,0,.8.672,2.086,2.086,0,0,0,.72-.117l.1.448a2.327,2.327,0,0,1-.906.165,1.229,1.229,0,0,1-1.339-1.312,1.286,1.286,0,0,1,1.269-1.4,1.129,1.129,0,0,1,1.12,1.264,1.615,1.615,0,0,1-.021.277Zm1.141-.457a.558.558,0,0,0-.539-.635.641.641,0,0,0-.6.635Z"
                        transform="translate(-1150.437 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_433"
                        data-name="Caminho 433"
                        d="M1194.322,3046.762l-.309,1.018h-.677l1.152-3.594h.837l1.168,3.594h-.709l-.325-1.018Zm1.029-.5-.283-.885c-.069-.219-.128-.464-.181-.672h-.01c-.054.208-.112.458-.171.672l-.277.885Z"
                        transform="translate(-1156.019 -3020.69)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_434"
                        data-name="Caminho 434"
                        d="M1202.231,3047.433c0-.341-.011-.618-.021-.864h.576l.032.4h.011a1.011,1.011,0,0,1,.891-.459,1.18,1.18,0,0,1,1.093,1.322,1.236,1.236,0,0,1-1.183,1.392.853.853,0,0,1-.731-.347h-.01v1.344h-.656Zm.656.646a.991.991,0,0,0,.016.181.6.6,0,0,0,.581.459c.416,0,.661-.347.661-.859,0-.458-.224-.832-.645-.832a.622.622,0,0,0-.592.485.88.88,0,0,0-.021.166Z"
                        transform="translate(-1161.309 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_435"
                        data-name="Caminho 435"
                        d="M1209.956,3047.406c0-.352-.005-.608-.021-.837h.565l.026.491h.016a.777.777,0,0,1,.715-.55.662.662,0,0,1,.155.016v.613a1.113,1.113,0,0,0-.2-.016.578.578,0,0,0-.587.5,1.156,1.156,0,0,0-.016.2v1.349h-.656Z"
                        transform="translate(-1165.915 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_436"
                        data-name="Caminho 436"
                        d="M1214.789,3048.051a.7.7,0,0,0,.8.672,2.09,2.09,0,0,0,.72-.117l.1.448a2.328,2.328,0,0,1-.906.165,1.228,1.228,0,0,1-1.338-1.312,1.286,1.286,0,0,1,1.269-1.4,1.129,1.129,0,0,1,1.12,1.264,1.621,1.621,0,0,1-.021.277Zm1.141-.457a.558.558,0,0,0-.539-.635.642.642,0,0,0-.6.635Z"
                        transform="translate(-1168.434 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_437"
                        data-name="Caminho 437"
                        d="M1221.352,3047.342c0-.3-.01-.55-.021-.773h.571l.032.39h.016a.954.954,0,0,1,.833-.449c.453,0,.922.294.922,1.115v1.541h-.655V3047.7c0-.374-.139-.655-.5-.655a.55.55,0,0,0-.512.383.623.623,0,0,0-.032.208v1.531h-.656Z"
                        transform="translate(-1172.709 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_438"
                        data-name="Caminho 438"
                        d="M1231.086,3043.711v3.055c0,.266.011.555.021.731h-.581l-.032-.411h-.01a.932.932,0,0,1-.843.469,1.19,1.19,0,0,1-1.114-1.328,1.235,1.235,0,0,1,1.162-1.387.827.827,0,0,1,.731.336h.011v-1.466Zm-.656,2.272a.963.963,0,0,0-.016-.176.576.576,0,0,0-.565-.459c-.421,0-.656.374-.656.853s.235.826.651.826a.578.578,0,0,0,.565-.464.72.72,0,0,0,.021-.192Z"
                        transform="translate(-1176.999 -3020.406)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_439"
                        data-name="Caminho 439"
                        d="M1237.252,3044.333a.368.368,0,1,1-.368-.357A.35.35,0,0,1,1237.252,3044.333Zm-.7,3.322v-2.6h.656v2.6Z"
                        transform="translate(-1181.762 -3020.564)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_440"
                        data-name="Caminho 440"
                        d="M1239.382,3048.874l.975-1.248c.134-.16.246-.288.378-.437v-.01h-1.258v-.522h2.058v.4l-.949,1.222c-.128.154-.25.3-.384.442v.011h1.365v.522h-2.186Z"
                        transform="translate(-1183.471 -3022.162)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_441"
                        data-name="Caminho 441"
                        d="M1246.06,3048.051a.7.7,0,0,0,.8.672,2.086,2.086,0,0,0,.72-.117l.1.448a2.323,2.323,0,0,1-.906.165,1.229,1.229,0,0,1-1.339-1.312,1.286,1.286,0,0,1,1.269-1.4,1.129,1.129,0,0,1,1.12,1.264,1.625,1.625,0,0,1-.021.277Zm1.141-.457a.558.558,0,0,0-.539-.635.641.641,0,0,0-.6.635Z"
                        transform="translate(-1187.077 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_442"
                        data-name="Caminho 442"
                        d="M1252.391,3048.559a1.426,1.426,0,0,0,.661.191c.288,0,.416-.123.416-.293s-.107-.261-.427-.378c-.506-.171-.714-.448-.714-.757,0-.458.379-.811.981-.811a1.492,1.492,0,0,1,.693.155l-.133.458a1.151,1.151,0,0,0-.55-.149c-.234,0-.362.118-.362.277s.123.24.453.363c.475.165.688.405.693.789,0,.474-.368.816-1.056.816a1.666,1.666,0,0,1-.789-.182Z"
                        transform="translate(-1191.146 -3022.074)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_443"
                        data-name="Caminho 443"
                        d="M1166.307,3013.634c0,1.626.057,2.971.113,4.091h-3.027l-.168-2.074h-.056a4.934,4.934,0,0,1-4.371,2.382c-2.494,0-4.764-1.485-4.764-5.941v-8.014h3.447v7.426c0,2.27.728,3.727,2.55,3.727a2.905,2.905,0,0,0,2.635-1.85,3.124,3.124,0,0,0,.2-1.065v-8.238h3.446Z"
                        transform="translate(-1132.588 -2996.778)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_444"
                        data-name="Caminho 444"
                        d="M1189.348,2988.6h3.447v8.127h.056a4.937,4.937,0,0,1,4.371-2.186c3.335,0,5.716,2.774,5.689,6.921,0,4.9-3.11,7.342-6.193,7.342a4.7,4.7,0,0,1-4.315-2.354h-.056l-.169,2.045h-2.942c.056-.925.112-2.438.112-3.839Zm3.447,14.263a3.774,3.774,0,0,0,.084.813,3.179,3.179,0,0,0,3.054,2.41c2.157,0,3.474-1.737,3.474-4.483,0-2.41-1.149-4.371-3.446-4.371a3.227,3.227,0,0,0-3.055,2.522,4.115,4.115,0,0,0-.111.9Z"
                        transform="translate(-1153.575 -2987.552)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_445"
                        data-name="Caminho 445"
                        d="M1229.808,3011.413c.085,2.466,2.018,3.53,4.2,3.53a10.268,10.268,0,0,0,3.783-.616l.5,2.382a12.8,12.8,0,0,1-4.764.869c-4.427,0-7.034-2.746-7.034-6.921,0-3.783,2.3-7.342,6.67-7.342,4.455,0,5.885,3.643,5.885,6.642a8.433,8.433,0,0,1-.113,1.457Zm6-2.41c.028-1.261-.533-3.335-2.83-3.335-2.13,0-3.027,1.934-3.167,3.335Z"
                        transform="translate(-1175.791 -2996.322)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_446"
                        data-name="Caminho 446"
                        d="M1127.549,3003.446l-8.257,4.251c0,.023,0,.045,0,.067v9.584h3.474v-8.014a3.507,3.507,0,0,1,.14-1.121,2.892,2.892,0,0,1,2.69-2.018c1.878,0,2.606,1.485,2.606,3.447v7.706h3.446v-8.1C1131.65,3005.385,1129.672,3003.747,1127.549,3003.446Z"
                        transform="translate(-1111.876 -2996.401)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_447"
                        data-name="Caminho 447"
                        d="M1119.292,3007.7c0,.023,0,.045,0,.068v3.829a25.255,25.255,0,0,1,4.219-4.421,2.849,2.849,0,0,1,1.005-.751,13.045,13.045,0,0,1,5.022-2.207,4.555,4.555,0,0,0-1.989-.768Z"
                        transform="translate(-1111.876 -2996.401)"
                        fill={bgColor}
                        opacity="0.66"
                    />
                    <path
                        id="Caminho_448"
                        data-name="Caminho 448"
                        d="M1103.56,2992.671l-1.138,7.184,19.186-9.67-4.326-4.185Z"
                        transform="translate(-1101.818 -2986)"
                        fill="#008c39"
                    />
                    <path
                        id="Caminho_449"
                        data-name="Caminho 449"
                        d="M1102.443,3004.822a.513.513,0,0,0-.318-.462l.02-3.872,19.09-9.829L1116.39,2986l-13.721,6.671-1.141,7.247.077.242-.021,4.209a.506.506,0,0,0-.3.446.487.487,0,0,0,.165.364l-.524,1.733h1.873l-.518-1.739A.483.483,0,0,0,1102.443,3004.822Z"
                        transform="translate(-1100.927 -2986)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_450"
                        data-name="Caminho 450"
                        d="M1117.281,2986l-13.721,6.671-1.138,7.184,19.186-9.67Z"
                        transform="translate(-1101.818 -2986)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_451"
                        data-name="Caminho 451"
                        d="M1110.959,2993.413l-6.118,5.2,8.182-.59,7.1-6.574Z"
                        transform="translate(-1103.261 -2989.251)"
                        fill="#f2f2f2"
                    />
                    <path
                        id="Caminho_452"
                        data-name="Caminho 452"
                        d="M1120.714,2995.974c-.182-.43-1.03-.5-2.178-.268l.007.006a3.53,3.53,0,0,0-2.085,1.473c-.66.9-.856,1.827-.544,2.309a.933.933,0,0,1-.451-.081c-.656-.318-.551-1.445.236-2.516.085-.114.174-.223.267-.326-2.2,1-3.738,2.347-3.445,3.038s2.374.458,4.636-.546S1121.012,2996.676,1120.714,2995.974Z"
                        transform="translate(-1107.817 -2991.707)"
                        fill={bgColor}
                    />
                    <path
                        id="Caminho_453"
                        data-name="Caminho 453"
                        d="M1119.224,2999.608a.93.93,0,0,0,.451.082c-.312-.482-.116-1.412.544-2.309a3.529,3.529,0,0,1,2.085-1.473.033.033,0,0,0-.007-.006,12.3,12.3,0,0,0-2.457.813c-.038.016-.074.035-.112.052a4.162,4.162,0,0,0-.266.327C1118.673,2998.163,1118.567,2999.29,1119.224,2999.608Z"
                        transform="translate(-1111.578 -2991.903)"
                        fill="#f2f2f2"
                    />
                </g>
            </g>
        </svg>
    )
}
export default Mube
