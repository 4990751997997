import React from 'react'

const Jeep = ({ bgColor = '#1f1e1d', darkMode = false }) => {
    if (darkMode) {
        bgColor = '#fff'
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="102"
            height="54"
            viewBox="0 0 102 54"
        >
            <g
                id="Grupo_2530"
                data-name="Grupo 2530"
                transform="translate(2493 -2963)"
            >
                <rect
                    id="Retângulo_351"
                    data-name="Retângulo 351"
                    width="102"
                    height="54"
                    transform="translate(-2493 2963)"
                    fill="#fff"
                    opacity="0"
                />
                <g
                    id="Grupo_1419"
                    data-name="Grupo 1419"
                    transform="translate(-2469 2980)"
                >
                    <g
                        id="Grupo_1418"
                        data-name="Grupo 1418"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Caminho_695"
                            data-name="Caminho 695"
                            d="M472.365,455.862a2.574,2.574,0,0,0-2.521,2.418l-.013.1h4.861v-.09a2.427,2.427,0,0,0-2.328-2.429Zm-13.089,0a2.559,2.559,0,0,0-2.517,2.418l-.017.1H461.6l0-.09a2.417,2.417,0,0,0-2.319-2.429Zm27.171,9.611a3.787,3.787,0,0,1-3.207-1.542l-.025-.033h-.181v5.089h-3.584V453.823h3.408v1.328h.176l.032-.035a3.556,3.556,0,0,1,3.092-1.628c3.724,0,5.046,3.362,5.046,6.247,0,2.851-1.469,5.738-4.758,5.738Zm-8.167-5.159H469.84l0,.09A2.535,2.535,0,0,0,472.5,463.1a2.314,2.314,0,0,0,2.192-1.264h3.355c-.645,2.276-2.718,3.64-5.588,3.64-3.83,0-6.208-2.309-6.208-6.025a5.976,5.976,0,0,1,6.113-5.959,5.683,5.683,0,0,1,4.237,1.655,6.853,6.853,0,0,1,1.692,4.8c0,.131-.008.248-.013.376Zm-13.093,0h-8.437v.09a2.528,2.528,0,0,0,2.656,2.693,2.318,2.318,0,0,0,2.193-1.264h3.354c-.645,2.276-2.722,3.64-5.588,3.64-3.822,0-6.2-2.309-6.2-6.025a5.976,5.976,0,0,1,6.114-5.959,5.7,5.7,0,0,1,4.237,1.655,6.851,6.851,0,0,1,1.683,4.8c0,.131,0,.248-.008.376Zm-13.24-.092c0,3.531-1.778,5.251-5.44,5.251-3.724,0-5.452-1.864-5.452-5.849v-.6h3.7V460.4a1.539,1.539,0,0,0,1.683,1.7c1.084,0,1.568-.659,1.568-2.141V449.506h3.942v10.716Zm33.346-4.027c-1.154,0-2.39.836-2.39,3.165,0,2.295.776,3.4,2.373,3.4,1.72,0,2.34-1.756,2.34-3.4,0-1.527-.612-3.165-2.324-3.165Z"
                            transform="translate(-441.055 -449.506)"
                            fill={bgColor}
                            fillRule="evenodd"
                        />
                        <path
                            id="Caminho_696"
                            data-name="Caminho 696"
                            d="M561.172,488.071a2.5,2.5,0,1,1,2.5-2.5,2.5,2.5,0,0,1-2.5,2.5Zm0-4.368a1.869,1.869,0,1,0,1.872,1.872,1.878,1.878,0,0,0-1.872-1.872Zm.4,3.217-.406-1.043h-.436v1.043H560.1v-2.636h1.162a1.009,1.009,0,0,1,.686.179.84.84,0,0,1,.275.614.72.72,0,0,1-.3.6.523.523,0,0,1-.156.08l.489,1.164Zm-.476-2.168h-.366v.673h.374c.345,0,.484-.1.484-.343s-.131-.331-.493-.331Z"
                            transform="translate(-509.263 -468.974)"
                            fill={bgColor}
                            fillRule="evenodd"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Jeep
