import React from 'react'

const Samsung = ({ bgColor = '#1f1e1d', darkMode = false }) => {
    if (darkMode) {
        bgColor = '#ffffff'
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="54"
            viewBox="0 0 101 54"
        >
            <g
                id="Grupo_2524"
                data-name="Grupo 2524"
                transform="translate(2391 -2801)"
            >
                <rect
                    id="Retângulo_343"
                    data-name="Retângulo 343"
                    width="101"
                    height="54"
                    transform="translate(-2391 2801)"
                    fill="#fff"
                    opacity="0"
                />
                <path
                    id="Caminho_772"
                    data-name="Caminho 772"
                    d="M819.932,613.551a1.391,1.391,0,0,1-.011-.587.926.926,0,0,1,1.027-.756.959.959,0,0,1,1.043,1.019v.694h2.8v-.789c0-2.44-2.2-2.829-3.786-2.829-1.992,0-3.621.661-3.918,2.493a3.845,3.845,0,0,0,.021,1.513c.487,2.294,4.474,2.958,5.053,4.409a1.424,1.424,0,0,1,.021.829.969.969,0,0,1-1.094.759,1,1,0,0,1-1.122-1.022l0-1.08H816.95v.857c0,2.509,1.971,3.264,4.079,3.264,2.027,0,3.692-.692,3.964-2.569a5.594,5.594,0,0,0-.013-1.836c-.472-2.356-4.728-3.052-5.048-4.368Zm71.529,2.03v1.637h1.15v1.624a2.13,2.13,0,0,1-.029.427,1.211,1.211,0,0,1-2.291,0,2.662,2.662,0,0,1-.03-.427v-5.128a2.2,2.2,0,0,1,.051-.53,1.174,1.174,0,0,1,2.235,0,2.625,2.625,0,0,1,.035.432v.624h2.826v-.369a6.69,6.69,0,0,0-.021-.743c-.213-2.1-1.946-2.771-3.935-2.771s-3.692.672-3.939,2.771c-.022.193-.057.537-.057.743v4.717a6.491,6.491,0,0,0,.046.74c.183,2.046,1.957,2.771,3.942,2.771s3.757-.725,3.945-2.771c.031-.377.034-.536.04-.74v-3.007Zm-19.48-4.92h-2.84v8.3a1.973,1.973,0,0,1-.024.424,1.013,1.013,0,0,1-1.078.816,1,1,0,0,1-1.068-.816,1.749,1.749,0,0,1-.03-.424v-8.3H864.1V618.7c0,.209.013.632.023.743.2,2.094,1.847,2.776,3.911,2.776s3.716-.682,3.917-2.776a6.815,6.815,0,0,0,.025-.743v-8.042Zm-26.072,0-1.416,8.769-1.414-8.769H838.5l-.241,11.219h2.8l.076-10.388,1.928,10.388h2.845l1.932-10.388.077,10.388h2.811l-.251-11.219Zm-16.947,0-2.079,11.219h3.03l1.567-10.388,1.528,10.388h3.009l-2.069-11.219Zm52.933,9.032-2.64-9.032h-4.163v11.1h2.751l-.159-9.322,2.837,9.322h3.994v-11.1h-2.773l.153,9.032Zm-25.474-6.113a1.483,1.483,0,0,1-.011-.58.92.92,0,0,1,1.016-.75.945.945,0,0,1,1.027,1.01v.684h2.768v-.781c0-2.418-2.168-2.8-3.737-2.8-1.974,0-3.59.653-3.883,2.471a3.736,3.736,0,0,0,.027,1.495c.48,2.268,4.429,2.928,5,4.361a1.5,1.5,0,0,1,.02.823c-.085.373-.338.749-1.081.749a.994.994,0,0,1-1.118-1.008v-1.077h-2.977v.857c0,2.479,1.943,3.228,4.035,3.228,2,0,3.659-.684,3.925-2.541a5.523,5.523,0,0,0-.019-1.818c-.466-2.328-4.674-3.027-4.991-4.325Z"
                    transform="translate(-3196.95 2211.698)"
                    fill={bgColor}
                />
            </g>
        </svg>
    )
}

export default Samsung
