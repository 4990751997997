import React from 'react'

const uxmobile = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 143 81"
    >
        <g
            id="Grupo_2561"
            data-name="Grupo 2561"
            transform="translate(3405 -1501)"
        >
            <g
                id="Grupo_2554"
                data-name="Grupo 2554"
                transform="translate(-4507 -1943)"
            >
                <rect
                    id="Retângulo_115"
                    data-name="Retângulo 115"
                    width="50"
                    height="50"
                    transform="translate(1106 3460)"
                    fill="#2eaa73"
                />
                <rect
                    id="Retângulo_116"
                    data-name="Retângulo 116"
                    width="25"
                    height="28"
                    transform="translate(1150 3491)"
                    fill="#f0d927"
                />
                <rect
                    id="Retângulo_123"
                    data-name="Retângulo 123"
                    width="25"
                    height="28"
                    transform="translate(1150 3491)"
                    fill="#f0d927"
                />
                <rect
                    id="Retângulo_124"
                    data-name="Retângulo 124"
                    width="50"
                    height="50"
                    transform="translate(1106 3460)"
                    fill="#00b01e"
                />
                <rect
                    id="Retângulo_125"
                    data-name="Retângulo 125"
                    width="25"
                    height="34"
                    transform="translate(1150 3485)"
                    fill="#151515"
                />
                <g
                    id="Retângulo_138"
                    data-name="Retângulo 138"
                    transform="translate(1119 3472)"
                    fill="none"
                    stroke="#000"
                    strokeWidth="1"
                    strokeDasharray="6"
                >
                    <rect width="46" height="47" stroke="none" />
                    <rect x="0.5" y="0.5" width="45" height="46" fill="none" />
                </g>
            </g>
            <rect
                id="Retângulo_355"
                data-name="Retângulo 355"
                width="143"
                height="81"
                transform="translate(-3405 1501)"
                fill="none"
            />
        </g>
    </svg>
)

export default uxmobile
