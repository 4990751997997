import React from 'react'

const Whoslate = ({
    letterColor = '#282828',
    faceColor = '#fff',
    bgColor = '#1f1e1d',
    darkMode = false,
}) => {
    if (darkMode) {
        letterColor = '#fff'
        faceColor = '#151515'
        bgColor = '#fff'
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="102"
            height="54"
            viewBox="0 0 102 54"
        >
            <g
                id="Grupo_2534"
                data-name="Grupo 2534"
                transform="translate(2493 -2855)"
            >
                <rect
                    id="Retângulo_345"
                    data-name="Retângulo 345"
                    width="102"
                    height="54"
                    transform="translate(-2493 2855)"
                    fill="#fff"
                    opacity="0"
                />
                <g
                    id="Camada_2"
                    data-name="Camada 2"
                    transform="translate(-2467 2863)"
                >
                    <g
                        id="Camada_2-2"
                        data-name="Camada 2"
                        transform="translate(0 0)"
                    >
                        <ellipse
                            id="Elipse_21"
                            data-name="Elipse 21"
                            cx="9.221"
                            cy="8.185"
                            rx="9.221"
                            ry="8.185"
                            transform="translate(15.615 3.07)"
                            fill={bgColor}
                        />
                        <path
                            id="Caminho_3541"
                            data-name="Caminho 3541"
                            d="M94.319,10.3a10.68,10.68,0,1,0-18.48,7.217l6.728,7.75a1.547,1.547,0,0,0,2.183,0l6.966-8.05h0A10.639,10.639,0,0,0,94.319,10.3ZM83.64,18.6A8.376,8.376,0,0,1,76,13.668a7.762,7.762,0,0,1-.741-3.368v-.148h0c.06-3.316,2.066-5.994,4.531-5.994A3.782,3.782,0,0,1,82.43,5.3a1.732,1.732,0,0,0,2.415,0A3.778,3.778,0,0,1,87.48,4.157c2.466,0,4.471,2.67,4.529,5.994h0v.154a7.734,7.734,0,0,1-.743,3.369A8.372,8.372,0,0,1,83.64,18.6Z"
                            transform="translate(-58.728 0.38)"
                            fill={bgColor}
                        />
                        <path
                            id="Caminho_3542"
                            data-name="Caminho 3542"
                            d="M137.074,35.519v-.06h0c-.051-2.622-1.633-4.728-3.583-4.728a3.206,3.206,0,0,0-2.614,1.541,2.438,2.438,0,0,0-.417,1.383v8.473a6.607,6.607,0,0,0,6.021-3.889,6.117,6.117,0,0,0,.585-2.659v-.035C137.066,35.532,137.074,35.527,137.074,35.519Zm-3.3.427a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,133.77,35.946Z"
                            transform="translate(-105.012 -24.662)"
                            fill="#fff"
                        />
                        <circle
                            id="Elipse_22"
                            data-name="Elipse 22"
                            cx="0.572"
                            cy="0.572"
                            r="0.572"
                            transform="translate(28.155 9.092)"
                            fill="#fff"
                        />
                        <path
                            id="Caminho_3543"
                            data-name="Caminho 3543"
                            d="M91.489,35.519v-.06h0c.047-2.622,1.629-4.728,3.575-4.728a3.222,3.222,0,0,1,2.614,1.541,2.436,2.436,0,0,1,.415,1.383v8.473a6.611,6.611,0,0,1-6.02-3.89,6.144,6.144,0,0,1-.585-2.659Zm3.3.427a1.6,1.6,0,1,0-1.6-1.6A1.6,1.6,0,0,0,94.794,35.946Z"
                            transform="translate(-73.643 -24.662)"
                            fill="#fff"
                        />
                        <circle
                            id="Elipse_23"
                            data-name="Elipse 23"
                            cx="0.572"
                            cy="0.572"
                            r="0.572"
                            transform="translate(20.61 9.092)"
                            fill="#fff"
                        />
                        <path
                            id="Caminho_3544"
                            data-name="Caminho 3544"
                            d="M0,151.8H1.365l.964,4.912c.094.445.176,1.088.246,1.685.14-.6.293-1.229.433-1.674l1.112-3.453H5.536l1.112,3.453c.14.445.281,1.088.421,1.674.07-.6.164-1.229.257-1.685l.96-4.916H9.652l-1.7,8.115H6.367l-1.17-3.64c-.117-.351-.257-.843-.375-1.311-.117.468-.257.96-.363,1.311l-1.17,3.64H1.709Z"
                            transform="translate(0 -122.116)"
                            fill="#282828"
                        />
                        <path
                            id="Caminho_3545"
                            data-name="Caminho 3545"
                            d="M56.28,149.221l1.311-.281v3.441a2.146,2.146,0,0,1,1.8-.831c1.182,0,1.951.866,1.951,2.259v3.8H60.037v-3.628c0-.737-.269-1.276-.913-1.276-.948,0-1.533.808-1.533,2.177v2.731H56.28Z"
                            transform="translate(-45.302 -119.813)"
                            fill="#282828"
                        />
                        <path
                            id="Caminho_3546"
                            data-name="Caminho 3546"
                            d="M89.28,165.4a3.044,3.044,0,1,1,6.086,0,3.044,3.044,0,1,1-6.086,0Zm4.728,0a1.7,1.7,0,1,0-1.685,1.951A1.787,1.787,0,0,0,94.008,165.4Z"
                            transform="translate(-71.865 -130.551)"
                            fill="#282828"
                        />
                        <path
                            id="Caminho_3547"
                            data-name="Caminho 3547"
                            d="M126.016,166.978a3.6,3.6,0,0,0,1.486.41c.492,0,.808-.269.808-.644,0-.975-2.434-.749-2.434-2.692a1.8,1.8,0,0,1,1.966-1.732,3.9,3.9,0,0,1,1.639.39l-.374,1.042a2.816,2.816,0,0,0-1.17-.281c-.492,0-.7.234-.7.585,0,.925,2.434.843,2.434,2.715a1.916,1.916,0,0,1-2.118,1.767,3.9,3.9,0,0,1-1.849-.468Z"
                            transform="translate(-101.181 -130.584)"
                            fill="#282828"
                        />
                        <path
                            id="Caminho_3548"
                            data-name="Caminho 3548"
                            d="M153.268,149.221l.995-.281v7.412c0,.375.14.527.48.527l-.117.866c-.8,0-1.365-.328-1.365-1.217Z"
                            transform="translate(-123.365 -119.813)"
                            fill="#282828"
                        />
                        <path
                            id="Caminho_3549"
                            data-name="Caminho 3549"
                            d="M171.62,167.764a2.319,2.319,0,0,1-1.97,1.01,3.1,3.1,0,0,1,0-6.156,2.323,2.323,0,0,1,1.9.925v-.78l.995.117v5.735h-.819Zm-.02-2.079a1.977,1.977,0,0,0-1.884-2.165,1.931,1.931,0,0,0-1.791,2.165,1.959,1.959,0,0,0,1.791,2.189A1.987,1.987,0,0,0,171.6,165.685Z"
                            transform="translate(-134.344 -130.824)"
                            fill="#282828"
                        />
                        <path
                            id="Caminho_3550"
                            data-name="Caminho 3550"
                            d="M203.188,157.038h-.808V156.3h.808v-1.416l.995-.3v1.72h1.393v.737h-1.393v3.336c0,.679.257,1.03.9,1.03a2.273,2.273,0,0,0,.737-.129l.2.878a3.511,3.511,0,0,1-.975.152,1.689,1.689,0,0,1-1.837-1.849Z"
                            transform="translate(-162.903 -124.353)"
                            fill="#282828"
                        />
                        <path
                            id="Caminho_3551"
                            data-name="Caminho 3551"
                            d="M230.945,168.406a4.629,4.629,0,0,1-1.929.39,2.9,2.9,0,0,1-3-3.066,2.853,2.853,0,0,1,2.731-3.09c1.814,0,2.645,1.416,2.645,3.172h-4.348a1.947,1.947,0,0,0,1.978,2.083,4.57,4.57,0,0,0,1.72-.363Zm-.562-3.355a1.552,1.552,0,0,0-1.627-1.521,1.656,1.656,0,0,0-1.648,1.521Z"
                            transform="translate(-181.932 -130.841)"
                            fill="#282828"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Whoslate
