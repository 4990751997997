import React, { useEffect } from 'react';

import { TimelineLite, Expo } from 'gsap';

import Bespoke from './svg/whatWeDo/bespoke';
import Cloud from './svg/whatWeDo/cloud';
import IOT from './svg/whatWeDo/iot';
import Platforms from './svg/whatWeDo/platforms';
import UX from './svg/whatWeDo/ux';
import UXMobile from './svg/whatWeDo/uxmobile';
import { injectIntl } from 'gatsby-plugin-intl';

const item = (item, index) => {
    return (
        <div className="item" key={`item ${index}`}>
            <div className="wrap-transition-box" />

            <div className="item-content">
                <div className="wrap-image">
                    <item.image />
                </div>

                <h3 className="item-title">{item.title}</h3>

                <span className="item-description">{item.description}</span>
            </div>
        </div>
    );
};

const playAnimation = (entries, startFunction) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            startFunction.play();
        }
    });
};

const WhatWeDo = ({ intl }) => {
    const items = [
        {
            title: 'UX/UI Strategy & Design',
            description: intl.formatMessage({
                id: 'what.items.uiux.description',
            }),
            image: UX,
        },
        {
            title: 'Web & Mobile',
            description: intl.formatMessage({
                id: 'what.items.webmobile.description',
            }),
            image: UXMobile,
        },
        {
            title: 'IoT & Smart Applications',
            description: intl.formatMessage({
                id: 'what.items.iot.description',
            }),
            image: IOT,
        },
        {
            title: 'Scalable Platforms',
            description: intl.formatMessage({
                id: 'what.items.platforms.description',
            }),
            image: Platforms,
        },
        {
            title: 'Cloud Solutions',
            description: intl.formatMessage({
                id: 'what.items.cloud.description',
            }),
            image: Cloud,
        },
        {
            title: 'Services Tailored',
            description: intl.formatMessage({
                id: 'what.items.bespoke.description',
            }),
            image: Bespoke,
        },
    ];

    const timeLineAnimation = [];
    let container;

    useEffect(() => {
        const platformsContent = container.querySelectorAll(' .item ');

        for (const key in platformsContent) {
            if (platformsContent.hasOwnProperty(key)) {
                const platformContent = platformsContent[key];

                let delay = 0.3 * key;
                delay = delay > 1 ? 0.15 * key : delay;

                timeLineAnimation[key] = new TimelineLite({
                    paused: true,
                    delay: 0.4,
                });

                timeLineAnimation[key].to(
                    platformContent,
                    0.4,
                    {
                        // top: '100%',
                        opacity: 1,
                        ease: Expo.easeIn,
                    },
                    delay
                );

                const intersectionObserver = new IntersectionObserver(
                    (entries, observer) =>
                        playAnimation(entries, timeLineAnimation[key])
                );
                intersectionObserver.observe(platformContent);
            }
        }
    }, []);

    return (
        <section className="what-we-do" id="whatWeDoSection" datanav="black">
            <div className="container">
                <div className="wrap-title">
                    <span className="description">
                        {intl.formatMessage({
                            id: 'what.description',
                        })}
                    </span>
                    <div className="wrap-what">
                        <h2
                            className="title"
                            dangerouslySetInnerHTML={{
                                __html: intl.formatMessage({
                                    id: 'what.title',
                                }),
                            }}
                        />
                    </div>
                </div>

                <div
                    className="wrap-items"
                    ref={(element) => (container = element)}
                >
                    {items.map(item)}
                </div>
            </div>
        </section>
    );
};

export default injectIntl(WhatWeDo);
