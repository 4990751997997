import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroBanner from '../components/heroBanner';
import HelloBanner from '../components/helloBanner';
import ClientsSay from '../components/clientsSay';
import Work from '../components/work';
import WhatWeDo from '../components/whatWeDo';
import ContinuousSatisfaction from '../components/continuousSatisfaction';
import Clients from '../components/clients';
import Blog from '../components/blog';

import ThemeContext from '../context/theme-context';

class IndexPage extends React.Component {
    componentDidMount() {
        const { closeMenu, changeLocale } = this.context;
        closeMenu();
    }

    render() {
        const { isMobile, darkMode, currentLocale, intl } = this.context;

        return (
            <Layout
                showNavLinks={true}
                initialMenuToggle={true}
                hideContact={isMobile}
                hasHeaderAnimation={true}
            >
                <SEO title="Home" />
                <HeroBanner />
                <HelloBanner />
                <ClientsSay />
                <Work isMobile={isMobile} />
                <WhatWeDo />
                <ContinuousSatisfaction />
                <Clients darkMode={darkMode} isMobile={isMobile} />
                <Blog currentLocale={currentLocale} isMobile={isMobile} />
            </Layout>
        );
    }
}

IndexPage.contextType = ThemeContext;

export default IndexPage;
